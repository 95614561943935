import React, { useState, useEffect } from 'react';

import Inputmask from 'inputmask';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import MapModal from '../../../../../../../modals/MapModal';
import DocModal from '../../../../../../../modals/DocModal';
import Textarea from '../../../../../../Textarea';

import classes from './styles.module.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function OfferForm({
  offer,
  jobId,
  counterOffer,
  counterOfferAmount,
  setCounterOfferAmount,
  message,
  setMessage,
}) {
  const [isDocModalVisible, setIsDocModalVisible] = useState(false);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);

  const { t } = useTranslation();

  let currencySymbol = '$';

  if (offer?.currency === 'TRY') {
    currencySymbol = '₺';
  }

  if (offer?.currency === 'EUR') {
    currencySymbol = '€';
  }

  useEffect(() => {
    Inputmask.extendAliases({
      currencyWithSybmol: {
        prefix: currencySymbol,
        groupSeparator: '.',
        alias: 'numeric',
        placeholder: '0',
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        clearMaskOnLostFocus: false,
      },
    });
    Inputmask({ alias: 'currencyWithSybmol' }).mask(`.CurrencyInput`);
  }, [currencySymbol]);

  return (
    <div className={classes.OfferForm}>
      <div className={classes.row}>
        <div className={classes.col}>
          <h4 className={classes.offerTitle}>
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.offerTitle'
            )}
          </h4>
        </div>
        <div className={classes.col}>
          <span
            className={classNames(classes.offerAmount, {
              [classes.counterOffer]: counterOffer,
            })}
          >
            {!offer?.counterAmount && currencySymbol}
            {offer?.counterAmount ||
              formatter.format(offer?.amount)?.substring(1)}
          </span>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <h5 className={classes.heading}>
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingPosition'
            )}
          </h5>
        </div>
        <div
          className={classes.col}
          style={{
            visibility: offer?.jobApplicationOfferAttachments.length
              ? 'visible'
              : 'hidden',
          }}
        >
          <div
            className={classes.additionalInfo}
            onClick={() => setIsDocModalVisible(true)}
          >
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.additionalInfo'
            )}
          </div>
        </div>
      </div>
      <div className={classNames(classes.row, classes.jobTitle)}>
        <span className={classes.heading}>{offer?.customJobTitle}</span>
      </div>
      <div className={classes.row}>
        <span className={classes.jobId}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.jobId'
          )}
          : {jobId}
        </span>
      </div>
      {(counterOffer || offer?.counterAmount) && (
        <div className={classNames(classes.counterOfferContainer, classes.row)}>
          <h5 className={classes.heading}>
            {t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMyOffer'
            )}
          </h5>
          <input
            readOnly={!counterOffer}
            type="text"
            className="CurrencyInput"
            value={counterOfferAmount || offer?.counterAmount}
            onChange={(event) => setCounterOfferAmount(event.target.value)}
          />
        </div>
      )}
      <div className={classes.row}>
        <h5 className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingWorkLocation'
          )}
        </h5>
      </div>
      <div className={classes.row}>
        <div className={classes.workLocation}>
          <span className={classes.location}>{offer?.locationName}</span>
          {offer?.location?.latitude && (
            <button type="button" onClick={() => setIsMapModalVisible(true)}>
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.clickToSeeInMap'
              )}
            </button>
          )}
        </div>
      </div>
      {(counterOffer || offer?.declineMessage) && (
        <>
          {!counterOffer && (
            <div className={classes.row}>
              <h5 className={classes.heading}>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMessage'
                )}
              </h5>
            </div>
          )}
          <div className={classes.row}>
            {counterOffer ? (
              <Textarea
                label={t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingMessage'
                )}
                height={64}
                placeholder={t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.typeYourMessage'
                )}
                name="message"
                value={message}
                setValue={setMessage}
              />
            ) : (
              <p className={classes.message}>{offer?.declineMessage}</p>
            )}
          </div>
        </>
      )}

      <div className={`${classes.row} ${classes.recruiterMessage}`}>
        <h5 className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Offer.Offered.OfferForm.headingRecruiterMessage'
          )}
        </h5>
      </div>
      <div className={classes.row}>
        <p className={classes.message}>{offer?.note}</p>
      </div>

      <DocModal
        show={isDocModalVisible}
        handleClose={() => setIsDocModalVisible(false)}
        jobTitle={offer?.jobApplicationOfferAttachments[0]?.displayName}
        doc={offer?.jobApplicationOfferAttachments[0]?.url}
      />
      <MapModal
        show={isMapModalVisible}
        handleClose={() => setIsMapModalVisible(false)}
        locations={[offer?.location]}
        offer
      />
    </div>
  );
}
