import classNames from 'classnames';
import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useUiContext from '../../../../hooks/useUiContext';
import classes from './styles.module.scss';

export default function Header({
  hideJobsApplicationInfo,
  title,
  location,
  jobId,
  toggleMode,
  hasMessages,
  hasUnreadMessages,
}) {
  const { t } = useTranslation();

  const { setIsNotificationsPopupVisible } = useUiContext();

  return (
    <div className={classes.Header}>
      <Link
        to={`/jobs/${jobId}`}
        className={classes.details}
        onClick={() => {
          hideJobsApplicationInfo();
          setIsNotificationsPopupVisible(false);
        }}
      >
        <div className={classes.icon} />
        <span>{t('common.details')}</span>
      </Link>

      <div className={classes.title}>
        <h2>{title}</h2>
        <div
          className={classes.location}
          style={{ visibility: location ? 'visible' : 'hidden' }}
        >
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.3989 10.7977C6.47818 10.211 9.375 8.35828 9.375 5.375C9.375 2.95875 7.41625 1 5 1C2.58375 1 0.625 2.95875 0.625 5.375C0.625 8.35828 3.52182 10.211 4.6011 10.7977C4.85136 10.9338 5.14864 10.9338 5.3989 10.7977ZM5 7.25C6.03553 7.25 6.875 6.41053 6.875 5.375C6.875 4.33947 6.03553 3.5 5 3.5C3.96447 3.5 3.125 4.33947 3.125 5.375C3.125 6.41053 3.96447 7.25 5 7.25Z"
              fill="white"
            />
            <path
              d="M5.3989 10.7977L5.68548 11.3248H5.68548L5.3989 10.7977ZM4.6011 10.7977L4.88767 10.2706L4.88767 10.2706L4.6011 10.7977ZM8.775 5.375C8.775 6.65985 8.15309 7.72876 7.35552 8.57303C6.5572 9.4181 5.62397 9.99242 5.11233 10.2706L5.68548 11.3248C6.25311 11.0163 7.30792 10.3709 8.22784 9.39708C9.1485 8.4225 9.975 7.07342 9.975 5.375H8.775ZM5 1.6C7.08488 1.6 8.775 3.29013 8.775 5.375H9.975C9.975 2.62738 7.74762 0.4 5 0.4V1.6ZM1.225 5.375C1.225 3.29013 2.91513 1.6 5 1.6V0.4C2.25238 0.4 0.025 2.62738 0.025 5.375H1.225ZM4.88767 10.2706C4.37603 9.99242 3.4428 9.41809 2.64448 8.57303C1.84691 7.72876 1.225 6.65985 1.225 5.375H0.025C0.025 7.07342 0.851499 8.4225 1.77216 9.39708C2.69208 10.3709 3.74689 11.0163 4.31452 11.3248L4.88767 10.2706ZM5.11233 10.2706C5.04077 10.3095 4.95923 10.3095 4.88767 10.2706L4.31452 11.3248C4.74348 11.558 5.25651 11.558 5.68548 11.3248L5.11233 10.2706ZM6.275 5.375C6.275 6.07916 5.70416 6.65 5 6.65V7.85C6.3669 7.85 7.475 6.7419 7.475 5.375H6.275ZM5 4.1C5.70416 4.1 6.275 4.67084 6.275 5.375H7.475C7.475 4.0081 6.3669 2.9 5 2.9V4.1ZM3.725 5.375C3.725 4.67084 4.29584 4.1 5 4.1V2.9C3.6331 2.9 2.525 4.0081 2.525 5.375H3.725ZM5 6.65C4.29584 6.65 3.725 6.07916 3.725 5.375H2.525C2.525 6.7419 3.6331 7.85 5 7.85V6.65Z"
              fill="#242833"
            />
          </svg>
          <span className={classes.city}>{location}</span>
        </div>
      </div>
      <div className={classes.rightSide}>
        {hasMessages && (
          <div
            className={classNames(classes.messages, {
              [classes.unread]: hasUnreadMessages,
            })}
            role="button"
            onClick={toggleMode}
            tabIndex={0}
          >
            <div className={classes.icon} />
            <h3>Message</h3>
          </div>
        )}
        <i className={classes.closeIcon} onClick={hideJobsApplicationInfo}>
          Close
        </i>
      </div>
    </div>
  );
}
