import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Avatar({
  avatarUrl,
  image,
  setImage,
  setIsAvatarRemoved,
  isAvatarRemoved,
}) {
  const fileInputRef = useRef();

  const { t } = useTranslation();

  return (
    <div className={classes.Avatar}>
      {image || (avatarUrl && !isAvatarRemoved) ? (
        <div className={classes.preview}>
          <button
            className={classes.deleteButton}
            type="button"
            onClick={() => {
              setIsAvatarRemoved(true);
              setImage(null);
            }}
          >
            {t('common.delete')}
          </button>
          <img
            src={
              image
                ? URL.createObjectURL(image)
                : `${avatarUrl}?format=png&mode=crop&width=200&height=200&q=70`
            }
            alt="Avatar"
          />
        </div>
      ) : (
        <div
          className={classes.uploader}
          onClick={() => fileInputRef.current.click()}
        >
          <span>
            {t('components.EditProfile.Avatar.uploadProfilePictureText')}
          </span>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={(event) => {
              setImage(event.target.files[0]);
              setIsAvatarRemoved(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
