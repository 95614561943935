import React, { useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Button from '../../../Button';
import BlackButton from '../BlackButton';
import ScheduleDetails from '../ScheduleDetails';
import Map from '../Map';

import { UiContext } from '../../../../context/UiContext';
import PublicService from '../../../../services/PublicService';
import classes from './styles.module.scss';

export default function OnboardInfo({
  schedule,
  showCancelForm,
  title,
  label,
  jobApplicationId,
  refetch,
  startDateSet,
}) {
  const { setIsFetching } = useContext(UiContext);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { t } = useTranslation();

  const acceptOnboarding = async () => {
    try {
      setIsFetching(true);

      await PublicService.acceptOnboarding({
        onboardingId: schedule.id,
        jobApplicationId,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const acceptJoining = async () => {
    try {
      setIsFetching(true);
      await PublicService.acceptJoining({
        joiningId: schedule.id,
        jobApplicationId,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.ScheduleInfo}>
      <div className={classes.container}>
        <p className={classes.heading}>{title}</p>
        <div className={classes.scheduleDetailsContainer}>
          <ScheduleDetails schedule={schedule} label={label} />
        </div>
        <div className={classes.mapContainer}>
          <Map />
        </div>
      </div>
      <div className={classes.buttons}>
        <BlackButton
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={showCancelForm}
        >
          {t(
            'components.MyDashboard.JobApplicationInfo.OnboardInfo.iCantMakeIt'
          )}
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          jobApplicationInfo
          onClick={startDateSet ? acceptJoining : acceptOnboarding}
        >
          {t(
            'components.MyDashboard.JobApplicationInfo.OnboardInfo.illBeThere'
          )}
        </Button>
      </div>
    </div>
  );
}
