/* eslint-disable no-console */
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import RICIBs from 'react-individual-character-input-boxes';
import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import otpLogo from '../../../assets/images/otp.svg';
import classes from './styles.module.scss';

const inputProps = {
  className: classes.input,
  placeholder: 'x',
  inputMode: 'numeric',
  pattern: '[0-9]*"',
};

export default function OtpVerificationForm({
  onSubmit,
  otp,
  setOtp,
  resendOtp,
  email,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const handleOutput = (string) => {
    setOtp(string);
  };

  return (
    <div className={classes.OtpVerificationForm}>
      <img
        src={otpLogo}
        alt="OTP"
        className={classes.logo}
        width={isTabletOrMobile ? 140 : 180}
        height={isTabletOrMobile ? 127 : 138}
      />
      <h1>{t('components.SignIn.OtpVerificationForm.heading')}</h1>
      <p className={classes.info}>
        {t('components.SignIn.OtpVerificationForm.infoText')}
      </p>
      <p className={classes.email}>{email}</p>
      <h2>{t('components.SignIn.OtpVerificationForm.enterOtp')}</h2>
      <div
        className={classes.inputs}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSubmit();
          }
        }}
      >
        <RICIBs
          amount={6}
          handleOutputString={handleOutput}
          inputProps={Array.from(Array(6).keys()).map(() => inputProps)}
          // inputRegExp={/^[a-zA-Z0-9_]+$/}
        />
      </div>
      <div className={classes.verifyButtonContainer}>
        <Button
          style={{ fontSize: 24, lineHeight: '30px' }}
          height={isTabletOrMobile ? 54 : 64}
          onClick={onSubmit}
          disabled={otp?.length < 6}
        >
          {t('common.continue')}
        </Button>
      </div>
      <p className={classes.notReceived}>
        {t('components.SignIn.OtpVerificationForm.notReceivedText.0')}{' '}
        <span className={classes.clickHere} onClick={resendOtp}>
          {t('components.SignIn.OtpVerificationForm.notReceivedText.1')}
        </span>{' '}
        {t('components.SignIn.OtpVerificationForm.notReceivedText.2')}
      </p>
    </div>
  );
}
