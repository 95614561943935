import React from 'react';

import TimeDropdown from '../../../TimeDropdown';
import Calendar from '../../../Calendar';

import classes from './styles.module.scss';

export default function Reschedule({
  rescheduledDate,
  setRescheduledDate,
  rescheduledTime,
  setRescheduledTime,
  rescheduledTimePeriod,
  setRescheduledTimePeriod,
  onBackButtonClick,
  noBackButton,
}) {
  return (
    <div className={classes.Reschedule}>
      {!noBackButton && (
        <i className={classes.backButton} onClick={onBackButtonClick}>
          Back
        </i>
      )}
      <div className={classes.calendarContainer}>
        <Calendar value={rescheduledDate} setValue={setRescheduledDate} />
      </div>
      <TimeDropdown
        timeValue={rescheduledTime}
        setTimeValue={setRescheduledTime}
        rescheduledTimePeriod={rescheduledTimePeriod}
        setRescheduledTimePeriod={setRescheduledTimePeriod}
      />
    </div>
  );
}
