/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { languages } from '../../../../constants/main';

import SearchBar from '../../../../components/SearchBar';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { hasAuthData } from '../../../../helpers/authStorage';
import classes from './styles.module.scss';

export default function MobileMenu({
  isSearchEnabled,
  setIsSearchEnabled,
  setSearchTerm,
  showSignInModal,
  logout,
  selectedLanguage,
  setSelectedLanguage,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { t } = useTranslation();

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setIsMenuVisible(false));

  return (
    <div className={classes.MobileMenu} ref={menuRef}>
      {isMenuVisible && (
        <button
          type="button"
          className={classes.closeButton}
          onClick={() => setIsMenuVisible(false)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66602 12.334L12.3327 1.66732"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12.334 12.334L1.66732 1.66732"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      )}
      <button
        type="button"
        className={classes.toggle}
        onClick={() => setIsMenuVisible(true)}
      >
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="2"
            y1="2.59961"
            x2="18"
            y2="2.59961"
            stroke="#242833"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="2"
            y1="9"
            x2="18"
            y2="9"
            stroke="#242833"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="2"
            y1="15.3994"
            x2="18"
            y2="15.3994"
            stroke="#242833"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </button>
      {isMenuVisible && (
        <ul className={classes.menu}>
          <li>
            <button type="button" onClick={() => setIsSearchEnabled(true)}>
              {t('components.Header.search')}
            </button>
            {isSearchEnabled && (
              <div className={classes.searchBarContainer}>
                <SearchBar
                  searchName="MainSearch"
                  setSearchTerm={setSearchTerm}
                  onCloseSearchClick={() => setIsSearchEnabled(false)}
                />
              </div>
            )}
          </li>
          <li>
            <Link to="/help">
              <span>{t('components.Header.help')}</span>
            </Link>
          </li>
          {hasAuthData() && (
            <Link to="/profile" onClick={() => setIsMenuVisible(false)}>
              <span>{t('components.Header.profile')}</span>
            </Link>
          )}
          <li>
            {hasAuthData() ? (
              <button type="button" className={classes.signIn} onClick={logout}>
                {t('components.Header.signOut')}
              </button>
            ) : (
              <button
                type="button"
                className={classes.signIn}
                onClick={showSignInModal}
              >
                {t('components.Header.signIn')}
              </button>
            )}
          </li>
          <li>
            <ul className={classes.languagesList}>
              {languages.map((lang) => (
                <li
                  className={classNames(classes.lang, {
                    [classes.selectedLanguage]:
                      selectedLanguage?.name === lang?.name,
                  })}
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {lang.name}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      )}
    </div>
  );
}
