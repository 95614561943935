import React, { useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import OfferForm from '../OfferForm';
import BlackButton from '../../../../BlackButton';
import Button from '../../../../../../Button';

import { UiContext } from '../../../../../../../context/UiContext';
import classes from './styles.module.scss';
import PublicService from '../../../../../../../services/PublicService';

export default function OfferDetails({
  offer,
  jobId,
  showDeclineOfferForm,
  refetch,
  withoutButtons,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { setIsFetching } = useContext(UiContext);

  const acceptOffer = async () => {
    try {
      setIsFetching(true);
      await PublicService.acceptOffer({
        offerId: offer.id,
        jobApplicationId: jobId,
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.OfferDetails}>
      <OfferForm offer={offer} jobId={jobId} />
      {!withoutButtons && (
        <div className={classes.buttons}>
          <BlackButton
            width={isTabletOrMobile ? '' : 300}
            onClick={showDeclineOfferForm}
            height={isTabletOrMobile ? 54 : 64}
          >
            {t('common.decline')}
          </BlackButton>
          <Button
            width={isTabletOrMobile ? '' : 300}
            height={isTabletOrMobile ? 54 : 64}
            jobApplicationInfo
            onClick={acceptOffer}
          >
            {t('common.accept')}
          </Button>
        </div>
      )}
    </div>
  );
}
