import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Button from '../../components/Button';
import VideoPlayer from '../../components/VideoPlayer';
import WhiteButton from '../../components/WhiteButton';

import useUiContext from '../../hooks/useUiContext';
import AnonymousService from '../../services/AnonymousService';
import classes from './styles.module.scss';

export default function LandingPage() {
  const { t } = useTranslation();

  const { isPreview } = useUiContext();

  const navigate = useNavigate();

  const { data: jobCount } = useQuery({
    queryKey: ['jobCount'],
    queryFn: AnonymousService.getJobCount,
  });

  const { data: contentMedias } = useQuery({
    queryKey: ['contentMedias'],
    queryFn: AnonymousService.getContentMedias,
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  /* useEffect(() => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute('content', `width=${222}, initial-scale=${1}`);
  }, []); */

  return (
    <div
      className={classNames(classes.LandingPage, {
        [classes.preview]: isPreview,
      })}
    >
      {isPreview && <div className={classes.overlay} />}
      <div className={classes.controlHeight} />
      <div className={classes.fullScreen}>
        <h1>
          {t('common.join')} <br />
          <span className={classes.kfc}>
            {t('pages.LandingPage.kfcTurkey')}
          </span>
        </h1>
        <div className={classes.info}>
          {!isTabletOrMobile && (
            <p>
              {t('pages.LandingPage.wishToAddValueToFriedChicken1')} <br />
              {t('pages.LandingPage.wishToAddValueToFriedChicken2')}
            </p>
          )}
          <p>
            {t('pages.LandingPage.discoverOpenPositions1')}{' '}
            <span className={classes.positionCount}>
              {jobCount?.jobPostCount}
            </span>{' '}
            {t('pages.LandingPage.discoverOpenPositions2')}
          </p>
        </div>
        <div className={classes.whiteButtonContainer}>
          <WhiteButton
            height={isTabletOrMobile ? 54 : 64}
            onClick={() => navigate('/jobs')}
          >
            {t('pages.LandingPage.joinOurTeam')}
          </WhiteButton>
        </div>
      </div>
      <div className={classes.contentBox}>
        <VideoPlayer
          url={contentMedias?.[0]?.url}
          text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint."
        />
        <div className={classes.textBlock}>
          <button
            type="button"
            className={classes.workButton}
            onClick={() => navigate('/')}
          >
            {t('pages.LandingPage.workAtKFC')}
          </button>
          <h2>
            Lorenlupsum <br /> Lorenlupsum <br /> Lorenlupsum
          </h2>
          <p>{t('pages.LandingPage.wishToAddValueToFriedChicken')}</p>
          <Button width={200} onClick={() => navigate('/jobs')}>
            Browse Jobs
          </Button>
        </div>
      </div>

      <div className={classes.contentBox}>
        {isTabletOrMobile && (
          <VideoPlayer
            url={contentMedias?.[0]?.url}
            text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint."
            leftButton
          />
        )}
        <div className={classes.textBlock}>
          <button
            type="button"
            className={classes.workButton}
            onClick={() => navigate('/')}
          >
            {t('pages.LandingPage.workAtKFC')}
          </button>
          <h2>
            Lorenlupsum <br /> Lorenlupsum <br /> Lorenlupsum
          </h2>
          <p>{t('pages.LandingPage.wishToAddValueToFriedChicken')}</p>
          <Button width={200} onClick={() => navigate('/jobs')}>
            Browse Jobs
          </Button>
        </div>
        {!isTabletOrMobile && (
          <VideoPlayer
            url={contentMedias?.[0]?.url}
            text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint."
            leftButton
          />
        )}
      </div>
      <div className={classes.contentBox}>
        <VideoPlayer
          url={contentMedias?.[0]?.url}
          text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint."
        />
        <div className={classes.textBlock}>
          <button
            type="button"
            className={classes.workButton}
            onClick={() => navigate('/')}
          >
            {t('pages.LandingPage.workAtKFC')}
          </button>
          <h2>
            Lorenlupsum <br /> Lorenlupsum <br /> Lorenlupsum
          </h2>
          <p>{t('pages.LandingPage.wishToAddValueToFriedChicken')}</p>
          <Button width={200} onClick={() => navigate('/jobs')}>
            {t('pages.LandingPage.browseJobs')}
          </Button>
        </div>
      </div>
    </div>
  );
}
