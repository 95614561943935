import React from 'react';

import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import closeMobileIcon from '../../../assets/images/close-mobile.svg';
import classes from './styles.module.scss';

export default function RecordVideoProgressMobile({
  steps,
  activeStep,
  isViewSampleVisible,
}) {
  const navigate = useNavigate();

  let title;

  if (steps[activeStep - 1]?.duration) {
    title = `${activeStep}. Video ${activeStep - 1}: ${
      steps[activeStep - 1].name
    }`;
  } else {
    title = `${activeStep}. ${steps[activeStep - 1]?.name}`;
  }

  const isTitleVisible =
    steps?.[activeStep - 1]?.name !== 'Additional Information';

  return (
    <div
      className={classNames(classes.RecordVideoProgressMobile, {
        [classes.absolute]: !isTitleVisible,
        [classes.viewSample]: isViewSampleVisible,
      })}
    >
      <button
        type="button"
        className={classNames(classes.closeButton, classes.button)}
        onClick={() => navigate('/')}
      >
        <img alt="Close" src={closeMobileIcon} />
      </button>

      <button
        type="button"
        className={classNames(classes.backButton, classes.button)}
        onClick={() => navigate(-1)}
      >
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
            fill="#242833"
          />
        </svg>
      </button>

      {steps.map((step, index) => {
        return (
          <div
            key={step.name}
            className={classNames(classes.step, {
              [classes.active]: index + 1 === activeStep,
              [classes.completed]: index + 1 < activeStep,
            })}
          />
        );
      })}
      {isTitleVisible && (
        <div className={classes.title}>
          <h1>{title}</h1>{' '}
          <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 25.5C20.4036 25.5 26 19.9036 26 13C26 6.09644 20.4036 0.5 13.5 0.5C6.59644 0.5 1 6.09644 1 13C1 19.9036 6.59644 25.5 13.5 25.5Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.8623 9.24998C10.1562 8.41456 10.7362 7.71011 11.4997 7.2614C12.2633 6.81268 13.1609 6.64865 14.0338 6.79837C14.9066 6.94809 15.6983 7.40188 16.2687 8.07939C16.839 8.75689 17.1511 9.61438 17.1498 10.5C17.1498 13 13.3998 14.25 13.3998 14.25"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 19.25H13.5125"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.2314 14.4849V16.2811"
              stroke="black"
              strokeLinecap="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
