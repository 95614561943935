import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CITY_IMG_URL } from '../../constants/main';
import classes from './styles.module.scss';

export default function CityCard({ city }) {
  const { t } = useTranslation();

  let sortedTags = city.tags.sort((a, b) => b.count - a.count);
  const areCountsEqual = sortedTags.every(
    (tag) => tag.count === sortedTags[0].count
  );

  if (areCountsEqual) {
    sortedTags = sortedTags.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <div
      className={classes.CityCard}
      style={{ backgroundImage: `url(${CITY_IMG_URL}/${city.coverImageName})` }}
    >
      <Link
        className={classes.container}
        to={`/jobs/city/${city.id}/${city.name}`}
      >
        <header>
          <h1>{city.name}</h1>
          <div
            className={classes.badge}
            style={sortedTags.length === 0 ? { visibility: 'hidden' } : {}}
          >
            {sortedTags[0]?.name}
          </div>
        </header>
        <footer>
          <div className={classes.jobCount}>
            {city.jobPostCount}{' '}
            {city.jobPostCount === 1
              ? t('components.CityCard.openRole')
              : t('components.CityCard.openRoles')}
          </div>
          <div className={classes.restaurantCount}>
            {city.restaurantCount}{' '}
            {city.restaurantCount === 1
              ? t('components.CityCard.branch')
              : t('components.CityCard.branches')}
          </div>
        </footer>
      </Link>
    </div>
  );
}
