import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export default function Answer({ answer, index, onClick, isActive }) {
  return (
    <div
      className={classNames(classes.Answer, {
        [classes.active]: isActive,
      })}
      onClick={onClick}
    >
      <div className={classes.index}>{alphabet[index]}</div>
      <div className={classes.answer}>
        <span>{answer.answer}</span>
      </div>
    </div>
  );
}
