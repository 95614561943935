import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../Button';

import classes from './styles.module.scss';

export default function RecordVideoControls({
  setIsViewSampleVisible,
  currentStep,
  hasImage,
  takePhoto,
  retakePhoto,
  usePhoto,
  steps,
  startVideoRecording,
  isVideoRecording,
  stopVideoRecording,
  hasVideo,
  retakeVideo,
  useVideo,
  uploadMedia,
}) {
  const { t } = useTranslation();

  let topButton = (
    <button
      type="button"
      className={classes.whiteButton}
      style={{ visibility: 'hidden' }}
    />
  );
  let bottomButton = (
    <button
      type="button"
      className={classes.whiteButton}
      style={{ visibility: 'hidden' }}
    />
  );

  if (currentStep === 1 && !hasImage) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={takePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.takePicture')}
      </Button>
    );
  } else if (currentStep === 1 && hasImage) {
    topButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={retakePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.retakePicture')}
      </button>
    );

    bottomButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={usePhoto}
      >
        {t('components.RecordVideo.RecordVideoControls.nextStep')}
      </Button>
    );
  } else if (
    steps[currentStep - 1]?.duration &&
    !isVideoRecording &&
    !hasVideo
  ) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={startVideoRecording}
      >
        {t('components.RecordVideo.RecordVideoControls.start')}
      </Button>
    );
    bottomButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={() => setIsViewSampleVisible(true)}
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="10" r="10" fill="#D52D34" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4999 16.4281C14.0503 16.4281 16.9284 13.5499 16.9284 9.99949C16.9284 6.44909 14.0503 3.57092 10.4999 3.57092C6.94946 3.57092 4.07129 6.44909 4.07129 9.99949C4.07129 13.5499 6.94946 16.4281 10.4999 16.4281ZM10.1398 7.41849L13.2121 9.12534C13.8979 9.50634 13.8979 10.4926 13.2121 10.8737L10.1398 12.5805C9.33994 13.0248 8.357 12.4465 8.357 11.5315V8.46748C8.357 7.5525 9.33994 6.97414 10.1398 7.41849Z"
            fill="white"
          />
        </svg>
        {t('components.RecordVideo.RecordVideoControls.viewSample')}
      </button>
    );
  } else if (
    steps[currentStep - 1]?.duration &&
    isVideoRecording &&
    !hasVideo
  ) {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={stopVideoRecording}
      >
        {t('components.RecordVideo.RecordVideoControls.stop')}
      </Button>
    );
    bottomButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={() => setIsViewSampleVisible(true)}
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="10" r="10" fill="#D52D34" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4999 16.4281C14.0503 16.4281 16.9284 13.5499 16.9284 9.99949C16.9284 6.44909 14.0503 3.57092 10.4999 3.57092C6.94946 3.57092 4.07129 6.44909 4.07129 9.99949C4.07129 13.5499 6.94946 16.4281 10.4999 16.4281ZM10.1398 7.41849L13.2121 9.12534C13.8979 9.50634 13.8979 10.4926 13.2121 10.8737L10.1398 12.5805C9.33994 13.0248 8.357 12.4465 8.357 11.5315V8.46748C8.357 7.5525 9.33994 6.97414 10.1398 7.41849Z"
            fill="white"
          />
        </svg>
        {t('components.RecordVideo.RecordVideoControls.viewSample')}
      </button>
    );
  } else if (hasVideo) {
    topButton = (
      <button
        type="button"
        className={classes.whiteButton}
        onClick={retakeVideo}
      >
        {t('components.RecordVideo.RecordVideoControls.retakeVideo')}
      </button>
    );
    bottomButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={useVideo}
      >
        {steps[currentStep].name === 'Review & Upload'
          ? t('components.RecordVideo.RecordVideoControls.reviewAll')
          : t('components.RecordVideo.RecordVideoControls.recordNext')}
      </Button>
    );
  } else {
    topButton = (
      <Button
        width={358}
        height={54}
        style={{ fontSize: 24 }}
        onClick={uploadMedia}
      >
        {t('components.RecordVideo.RecordVideoControls.next')}
      </Button>
    );
  }

  return (
    <div className={classes.RecordVideoControls}>
      <div
        className={classes.textContainer}
        style={{
          visibility:
            steps[currentStep - 1]?.name === 'Review & Upload'
              ? 'hidden'
              : 'visible',
        }}
      >
        <h1>Tittle 1</h1>
        <p className={classes.instructions}>
          {t('components.RecordVideo.RecordVideoControls.lookAtCamera')}
        </p>
        <div className={classes.text}>
          <p>I offer digital services,being an illustrator and graphical</p>
          <p>Text Line 2</p>
          <p>Text Line 3</p>
          <p>Text Line 4</p>
          <p>Text Line 5</p>
        </div>
        <div className={classes.info}>
          <p>I offer digital services,being an illustrator and graphical</p>
          <p>Text Line 4</p>
        </div>
      </div>
      <div className={classes.buttons}>
        {topButton}
        {bottomButton}
      </div>
    </div>
  );
}
