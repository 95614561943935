/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { format } from 'timeago.js';
import classNames from 'classnames';

import NotificationButtons from './NotificationButtons';

import PublicService from '../../../services/PublicService';
import useGetNotificationData from '../../../hooks/useGetNotificationData';
import classes from './styles.module.scss';

export default function Notification({
  notification,
  showJobApplicationInfo,
  refetch,
}) {
  const notificationData = useGetNotificationData(notification, refetch);

  const markNotificationAsRead = async () => {
    try {
      await PublicService.readNotification({
        notificationId: notification.id,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li
      onClick={() => {
        if (!notification.jobApplication) {
          return;
        }

        showJobApplicationInfo(notification.jobApplication);
        markNotificationAsRead();
      }}
      className={classNames(classes.Notification, {
        [classes.read]: !!notification.readAt,
      })}
    >
      <h4 className={classes.title}>{notificationData.title}</h4>
      <div className={classes.text}>
        {notificationData.text}.
        <span className={classes.createdAt}>
          {' '}
          {format(notification.createdAt)}
        </span>
      </div>
      {!notificationData.isOutdated && (
        <div className={classes.buttons}>
          <NotificationButtons
            buttonsData={notificationData.buttonsData}
            markNotificationAsRead={markNotificationAsRead}
          />
        </div>
      )}
    </li>
  );
}
