import React, { useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useUiContext from '../../../hooks/useUiContext';
import linkedInIcon from '../../../assets/images/footer/linkedin.svg';
import tikTokIcon from '../../../assets/images/footer/tiktok.svg';
import facebookIcon from '../../../assets/images/footer/facebook.svg';
import instagramIcon from '../../../assets/images/footer/instagram.svg';
import twitterIcon from '../../../assets/images/footer/twitter.svg';
import isInIframe from '../../../helpers/isInIframe';
import parmak from '../../../assets/images/parmak.png';
import badges from '../../../assets/images/badges.png';
import classes from './styles.module.scss';

const icons = {
  Instagram: instagramIcon,
  Facebook: facebookIcon,
  Twitter: twitterIcon,
  LinkedIn: linkedInIcon,
  TikTok: tikTokIcon,
};

export default function Footer({ tenantSettings, refetch }) {
  const { isPreview } = useUiContext();

  const { t } = useTranslation();

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const links = tenantSettings?.sodialMediaLinks
    ?.filter((link) => {
      return !link.isHidden;
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);

  const isIframe = isInIframe();

  useEffect(() => {
    const readMessage = (event) => {
      if (event.data === 'reload') {
        refetch();
      }
    };

    window.addEventListener('message', readMessage);

    return () => {
      window.removeEventListener('message', readMessage);
    };
  }, [refetch]);

  return (
    <footer
      className={classNames(classes.Footer, {
        [classes.nofooter]: isIframe && !isHomePage && !isPreview,
        [classes.preview]: isPreview,
      })}
    >
      <nav>
        <ul>
          <li>
            <Link to="/our-story">{t('components.Footer.ourStory')}</Link>
          </li>
          <li>
            <Link to="/menus">{t('components.Footer.menus')}</Link>
          </li>
          <li>
            <Link to="/halal-certificates">
              {t('components.Footer.certificates')}
            </Link>
          </li>
          <li>
            <Link to="/colonels-club">{t('components.Footer.club')}</Link>
          </li>
          <li>
            <Link to="/information-services">
              {t('components.Footer.services')}
            </Link>
          </li>
          <li>
            <Link to="/terms-of-use">{t('components.Footer.terms')}</Link>
          </li>
          <li>
            <Link to="/career">{t('components.Footer.career')}</Link>
          </li>
          <li>
            <Link to="/contact-us">{t('components.Footer.contactUs')}</Link>
          </li>
        </ul>
      </nav>
      <div className={classes.badges}>
        <img
          src={parmak}
          className={classes.parmakImg}
          alt="Parmak isirtan lezzt"
        />
        <img src={badges} className={classes.badgesImg} alt="Badges" />
      </div>
      <div className={classes.socButtons}>
        <h2>{t('components.Footer.followUs')}</h2>
        <ul>
          {links?.map((link) => {
            return (
              <li key={link.id}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  <img src={icons[link.type]} alt={link.type} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={classes.copyright}>
        <span>
          {t('components.Footer.rights.0')} {new Date().getFullYear()}{' '}
          {t('components.Footer.rights.1')}
        </span>
      </div>
    </footer>
  );
}
