import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Button({
  children,
  onClick,
  width,
  height,
  style,
  disabled,
  isBlackButton,
  jobApplicationInfo,
  reference,
  likeDisabled,
}) {
  if (isBlackButton) {
    return (
      <button
        ref={reference}
        type="button"
        className={classes.ButtonBlack}
        onClick={onClick}
        style={{ ...style, width, height }}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  return (
    <button
      ref={reference}
      type="button"
      className={classNames(classes.Button, {
        [classes.jobApplicationInfo]: jobApplicationInfo,
        [classes.likeDisabled]: likeDisabled,
      })}
      onClick={onClick}
      style={{ ...style, width, height }}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
