/* eslint-disable react/jsx-handler-names */
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import QrCodeModal from '../modals/QrCodeModal';
import AlertModal from '../modals/AlertModal';

import { hasAuthData } from '../helpers/authStorage';

export const UiContext = createContext({
  showUnknownErrorModal: () => {},
  showModal: () => {},
  showQrCodeModal: () => {},
  isUserProfileFilled: null,
  checkIfUserProfileFilled: () => {},
  previousResume: {
    type: '',
    jobId: 0,
    videos: [],
    doc: {},
  },
  setPreviousResume: () => {},
  setIsFetching: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  dashboardJobApplicationsFilter: '',
  setDashboardJobApplicationsFilter: () => {},
  isFetching: false,
  isPreview: false,
  setIsPreview: () => {},
  isNotificationsPopupVisible: false,
  setIsNotificationsPopupVisible: () => {},
});

export default function UiContextProvider({ children }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    title: null,
    text: null,
    onConfirm: null,
    onCancel: null,
    noCancel: false,
  });
  const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);
  const [isUserProfileFilled, setIsUserProfileFilled] = useState(null);
  const [previousResume, setPreviousResume] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isNotificationsPopupVisible, setIsNotificationsPopupVisible] =
    useState(false);

  const { t } = useTranslation();
  const [dashboardJobApplicationsFilter, setDashboardJobApplicationsFilter] =
    useState(t('common.myJobsFilters.Applied'));

  useEffect(() => {
    if (!isModalVisible) {
      setTimeout(() => {
        setModalData({
          title: null,
          text: null,
          onConfirm: null,
        });
      }, 200);
    }
  }, [isModalVisible]);

  const showModal = useCallback(
    ({ title, text, onConfirm, noCancel, onCancel }) => {
      setModalData({
        title,
        text,
        onConfirm,
        onCancel,
        noCancel,
      });
      setIsModalVisible(true);
    },
    []
  );

  const showUnknownErrorModal = useCallback(() => {
    showModal({
      title: 'Ooops',
      text: 'Something wrong. Please try again after some time. ',
      dismissButtonLabel: 'Got It',
    });
  }, [showModal]);

  const showQrCodeModal = useCallback(() => {
    setIsQrCodeModalVisible(true);
  }, []);

  useEffect(() => {
    const isAuth = hasAuthData();
    setIsAuthenticated(isAuth);
  }, []);

  const checkIfUserProfileFilled = useCallback((userData) => {
    if (!userData) {
      return false;
    }

    const isProfileFilled = Boolean(
      userData.userProfile?.firstName &&
        userData.userProfile?.lastName &&
        userData.userProfile?.phone
    );

    setIsUserProfileFilled(isProfileFilled);

    return isProfileFilled;
  }, []);

  const value = useMemo(
    () => ({
      showUnknownErrorModal,
      showModal,
      showQrCodeModal,
      checkIfUserProfileFilled,
      isUserProfileFilled,
      previousResume,
      setPreviousResume,
      setIsFetching,
      isAuthenticated,
      setIsAuthenticated,
      dashboardJobApplicationsFilter,
      setDashboardJobApplicationsFilter,
      isFetching,
      isPreview,
      setIsPreview,
      isNotificationsPopupVisible,
      setIsNotificationsPopupVisible,
    }),
    [
      showUnknownErrorModal,
      showModal,
      showQrCodeModal,
      checkIfUserProfileFilled,
      isUserProfileFilled,
      previousResume,
      setPreviousResume,
      isAuthenticated,
      dashboardJobApplicationsFilter,
      isFetching,
      isPreview,
      isNotificationsPopupVisible,
    ]
  );

  return (
    <UiContext.Provider value={value}>
      {children}
      <AlertModal
        onCancel={modalData.onCancel}
        noCancel={modalData.noCancel}
        show={isModalVisible}
        title={modalData.title}
        text={modalData.text}
        handleClose={() => {
          setIsModalVisible(false);
          setTimeout(() => {
            setModalData({
              title: null,
              text: null,
              onConfirm: null,
              noCancel: false,
            });
          }, 300);
        }}
        onConfirm={modalData.onConfirm}
      />
      <QrCodeModal
        show={isQrCodeModalVisible}
        handleClose={() => {
          setIsQrCodeModalVisible(false);
        }}
      />
      {isFetching && <Loader />}
    </UiContext.Provider>
  );
}
