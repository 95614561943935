import React from 'react';

import Modal from 'react-bootstrap/Modal';
import classes from './styles.module.scss';

export default function DocModal({ show, handleClose, doc, jobTitle }) {
  return (
    <Modal
      show={show}
      centered
      className={classes.DocModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h1>{jobTitle}</h1>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <div className={classes.doc}>
          <iframe
            width="100%"
            height="100%"
            title="doc"
            src={`https://docs.google.com/gview?url=${doc}&embedded=true`}
          />
        </div>
      </div>
    </Modal>
  );
}
