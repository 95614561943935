/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default React.memo(
  function ViewSample({ close }) {
    const videoRef = useRef();

    const { t } = useTranslation();

    return (
      <div className={classes.ViewSample}>
        <div className={classes.container}>
          <video
            autoPlay
            src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_10MB.mp4"
            ref={videoRef}
          />
          <header className={classes.header}>
            <h1>{t('components.RecordVideo.Video.ViewSample.sample')}</h1>
            <button type="button" onClick={close}>
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.246094"
                  y="0.990234"
                  width="18.6102"
                  height="18.6102"
                  rx="9.30511"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.55149 9.71253L11.7549 7.50916C11.916 7.34802 12.1773 7.34802 12.3384 7.50916C12.4995 7.67029 12.4995 7.93155 12.3384 8.09268L10.135 10.2961L12.3389 12.4999C12.5 12.6611 12.5 12.9223 12.3389 13.0835C12.1778 13.2446 11.9165 13.2446 11.7554 13.0835L9.55149 10.8796L7.34793 13.0831C7.18679 13.2443 6.92554 13.2443 6.76441 13.0831C6.60327 12.922 6.60327 12.6608 6.76441 12.4996L8.96797 10.2961L6.76491 8.093C6.60378 7.93186 6.60378 7.67061 6.76491 7.50948C6.92605 7.34834 7.1873 7.34834 7.34844 7.50948L9.55149 9.71253Z"
                  fill="black"
                />
              </svg>
            </button>
          </header>
          <button
            type="button"
            className={classes.replayButton}
            onClick={() => {
              // videoRef.current?.pause();
              videoRef.current.currentTime = 0;
              videoRef.current?.play();
            }}
          >
            <svg
              width="39"
              height="38"
              viewBox="0 0 39 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19.4051" cy="18.6946" r="18.6102" fill="white" />
              <g clipPath="url(#clip0_1019_33161)">
                <path
                  d="M20.6125 7.52917C20.8599 7.62355 21.1247 7.61031 21.3807 7.64829C23.2999 7.93142 25.0188 8.68124 26.565 9.84711C26.687 9.93918 26.6594 9.98061 26.5708 10.0675C26.191 10.4398 25.8117 10.8127 25.4457 11.1977C25.3238 11.326 25.2489 11.3439 25.097 11.2328C24.1366 10.5336 23.0801 10.0272 21.9194 9.77057C18.6341 9.04435 15.7603 9.84941 13.375 12.2214C11.4956 14.0905 10.622 16.4027 10.7665 19.0498C10.923 21.9184 12.2155 24.2105 14.5161 25.9138C16.227 27.1804 18.1743 27.776 20.2908 27.6143C23.8863 27.3386 26.4752 25.5277 28.0566 22.2769C28.0911 22.2062 28.1182 22.1319 28.1515 22.0606C28.3898 21.5478 28.9474 21.3205 29.4429 21.5335C29.9452 21.7492 30.1754 22.3241 29.9562 22.8501C29.3715 24.2536 28.5342 25.488 27.4374 26.5388C25.7853 28.1213 23.8253 29.0961 21.5724 29.4776C21.3042 29.5231 21.0314 29.5426 20.761 29.5754C20.7224 29.58 20.6718 29.5576 20.6557 29.6186C20.067 29.6186 19.4789 29.6186 18.8902 29.6186C18.8666 29.5507 18.8067 29.5795 18.7618 29.5731C18.4148 29.5208 18.065 29.4839 17.7208 29.4183C15.7643 29.0437 14.0281 28.2018 12.5417 26.876C10.1881 24.7767 8.92213 22.14 8.78114 18.9836C8.69252 17.0063 9.1103 15.124 10.0696 13.3913C11.8454 10.1843 14.5455 8.26115 18.1651 7.65002C18.4068 7.60916 18.6588 7.6293 18.8907 7.52917C19.4645 7.52917 20.0388 7.52917 20.6125 7.52917Z"
                  fill="black"
                />
                <path
                  d="M16.545 18.8766C16.545 17.7913 16.557 16.7054 16.5404 15.6201C16.5306 14.9986 16.7481 14.5239 17.3115 14.2459C17.9071 13.9519 18.5015 13.9795 19.035 14.3812C19.7681 14.9336 20.4788 15.516 21.1987 16.0862C21.941 16.6738 22.6828 17.2631 23.4263 17.85C24.1743 18.441 24.1778 19.2979 23.4343 19.8871C22.0055 21.0185 20.5783 22.1521 19.1489 23.2823C18.5078 23.7893 17.6959 23.8169 17.0807 23.3577C16.7072 23.0792 16.5427 22.7034 16.5444 22.2407C16.5473 21.1198 16.545 19.9982 16.545 18.8766Z"
                  fill="black"
                />
                <path
                  d="M29.4867 11.7904C29.4867 12.2145 29.4901 12.6386 29.4855 13.0621C29.4798 13.6134 29.1109 14.0398 28.5613 14.0548C27.6659 14.079 26.7688 14.0709 25.8728 14.0531C25.451 14.0444 25.1656 13.7935 25.0205 13.404C24.8807 13.027 24.9561 12.6772 25.2392 12.3912C26.0978 11.5239 26.9593 10.659 27.8265 9.80046C28.128 9.50179 28.4986 9.45403 28.8819 9.61055C29.2686 9.7688 29.478 10.0721 29.4844 10.4962C29.4907 10.9272 29.4855 11.3582 29.4855 11.7898C29.4861 11.7904 29.4867 11.7904 29.4867 11.7904Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1019_33161">
                  <rect
                    x="8.76953"
                    y="7.52917"
                    width="21.2688"
                    height="22.0894"
                    rx="10.6344"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    );
  },
  () => {
    return false;
  }
);
