/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Textarea({
  label,
  name,
  placeholder,
  height,
  maxLength,
  value,
  setValue,
  info,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Textarea, {
        [classes.withInfo]: !!info,
      })}
    >
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      {info && <span className={classes.info}>{info}</span>}
      <textarea
        value={value}
        onChange={(event) => setValue(event.target.value)}
        name={name}
        id={name}
        placeholder={placeholder}
        style={{ height }}
        maxLength={maxLength}
      />
      {maxLength && (
        <div className={classes.charCount}>
          <span>
            {maxLength - value?.length}{' '}
            {t('components.Textarea.charactersLeft')}
          </span>
        </div>
      )}
    </div>
  );
}
