import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getAuthData } from '../../../../../helpers/authStorage';
import classes from './styles.module.scss';

export default function Message({ message }) {
  const { t } = useTranslation();

  const { userId } = getAuthData();

  const isMyMessage = +userId === message.authorId;

  return (
    <div
      className={classNames(classes.Message, {
        [classes.my]: isMyMessage,
      })}
    >
      <div className={classes.header}>
        <h4>
          {isMyMessage
            ? t(
                'components.MyDashboard.JobApplicationInfo.Messages.Message.myReply'
              )
            : t(
                'components.MyDashboard.JobApplicationInfo.Messages.Message.messageFromKFC'
              )}
        </h4>
        <span className={classes.createdAt}>
          {moment(message.createdAt).format('DD MMMM YYYY hh:mm A')}
        </span>
      </div>
      <div className={classes.message}>
        {message.message}
        <br />
        <br />
        {t(
          'components.MyDashboard.JobApplicationInfo.Messages.Message.thankYou'
        )}{' '}
        <br />
        {message.author.fullName}
      </div>
    </div>
  );
}
