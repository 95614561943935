import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import OfferDetails from '../JobApplicationStatuses/Offer/Offered/OfferDetails';
import Button from '../../../Button';
import BlackButton from '../BlackButton';

import CalendarButton from '../../../CalendarButton';
import classes from './styles.module.scss';

export default function MessageWithButton({
  message,
  buttonLabel,
  onButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  noButton,
  buttonDisabled,
  offer,
  jobId,
  withCalendarButton,
  schedule,
  scheduleType,
}) {
  const [mode, setMode] = useState('message');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  let buttonWidth = secondaryButtonLabel ? 300 : 444;

  const showOffer = () => {
    setMode('offer');
  };

  if (isTabletOrMobile) {
    buttonWidth = '';
  }

  return (
    <div
      className={classNames(classes.MessageWithButton, {
        [classes.offer]: mode === 'offer',
      })}
    >
      {mode === 'offer' && (
        <i className={classes.backIcon} onClick={() => setMode('message')}>
          Back
        </i>
      )}
      {mode === 'offer' ? (
        <OfferDetails withoutButtons offer={offer} jobId={jobId} />
      ) : (
        message
      )}
      {!noButton && (
        <div className={classes.buttonsContainer}>
          {offer && mode !== 'offer' && (
            <div className={classes.offerButtonContainer}>
              <button
                className={classes.offerButton}
                type="button"
                onClick={showOffer}
              >
                See Offer
              </button>
            </div>
          )}
          <div className={classes.buttons}>
            {withCalendarButton && (
              <div className={classes.calendarButton}>
                <CalendarButton
                  schedule={schedule}
                  scheduleType={scheduleType}
                />
              </div>
            )}
            {secondaryButtonLabel && (
              <BlackButton
                onClick={onSecondaryButtonClick}
                width={isTabletOrMobile ? '' : 300}
                height={isTabletOrMobile ? 54 : 64}
              >
                {secondaryButtonLabel}
              </BlackButton>
            )}
            <Button
              width={buttonWidth}
              height={isTabletOrMobile ? 54 : 64}
              style={{ fontSize: 24, fontWeight: 500 }}
              onClick={onButtonClick}
              disabled={buttonDisabled}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
