import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import QrCodeModal from '../../modals/QrCodeModal';

import closeMobileIcon from '../../assets/images/close-mobile.svg';
import qrIcon from '../../assets/images/qr.svg';
import classes from './styles.module.scss';

export default function NavHeader({
  top,
  noBack,
  backIcon,
  leftJobApplication,
  recordVideoPage,
}) {
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <>
      <button
        style={{ top }}
        type="button"
        className={classNames(classes.closeButton, classes.button)}
        onClick={() => {
          navigate(-1);
          leftJobApplication?.();
        }}
      >
        {isTabletOrMobile ? (
          <img alt="Close" src={closeMobileIcon} />
        ) : (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L13 13"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
      {!isTabletOrMobile && recordVideoPage && (
        <button
          type="button"
          className={classes.qrButton}
          onClick={() => setIsQrModalVisible(true)}
        >
          Record on Mobile
          <img alt="QR" src={qrIcon} />
        </button>
      )}
      {!noBack && (
        <button
          style={{ top }}
          type="button"
          className={classNames(classes.backButton, classes.button)}
          onClick={() => {
            navigate(-1);
            leftJobApplication?.();
          }}
        >
          {isTabletOrMobile || backIcon ? (
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
                fill="#242833"
              />
            </svg>
          ) : (
            t('common.back')
          )}
        </button>
      )}
      <QrCodeModal
        show={isQrModalVisible}
        handleClose={() => setIsQrModalVisible(false)}
      />
    </>
  );
}
