import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import BlackButton from '../../components/MyDashboard/JobApplicationInfo/BlackButton';
import MainInfoForm from '../../components/EditProfile/MainInfoForm';
import WordResume from '../../components/EditProfile/Resume/WordResume';
import VideoResume from '../../components/EditProfile/Resume/VideoResume';
import ProfileDropdown from '../../components/EditProfile/ProfileDropdown';
import Avatar from '../../components/EditProfile/Avatar';
import Button from '../../components/Button';
import AdditionalInfoForm from '../../components/EditProfile/AdditionalInfoForm';
import NavHeader from '../../components/NavHeader';

import { getStaticDate } from '../../helpers/timezones';
import { IMAGES_URL } from '../../constants/main';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import PublicService from '../../services/PublicService';
import { UiContext } from '../../context/UiContext';

export default function EditProfilePage() {
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [education, setEducation] = useState('');
  const [hasWorkExperience, setHasWorkExperience] = useState();
  const [isFresher, setIsFresher] = useState(false);
  const [jobs, setJobs] = useState([
    {
      id: Math.random(),
      jobTitle: '',
      companyName: '',
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);
  const [image, setImage] = useState(null);
  const [isAvatarRemoved, setIsAvatarRemoved] = useState(false);

  const [email, setEmail] = useState('');
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [isDocResumeDeleted, setIsDocResumeDeleted] = useState(false);
  const [resumeVideo, setResumeVideo] = useState(null);
  const [isVideoResumeDeleted, setIsVideoResumeDeleted] = useState(false);

  const { showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const hasProfileOptionsUseEffectRun = useRef();

  const { t } = useTranslation();

  const profileOptions = [
    t('pages.EditProfilePage.openToWork'),
    t('pages.EditProfilePage.privateMode'),
    t('pages.EditProfilePage.deleteMyProfile'),
  ];
  const [activeProfileOption, setActiveProfileOption] = useState(
    t('pages.EditProfilePage.openToWork')
  );

  const {
    data: user,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const uploadAvatar = async () => {
    const response = await PublicService.uploadAvatar(image);
    return response.fileName;
  };

  const showUpdateProfileSuccessModal = (prevStatus, currentStatus) => {
    if (prevStatus === currentStatus) {
      showModal({
        title: t('common.success'),
        text: t('common.changesSaved'),
      });

      return;
    }

    if (!currentStatus) {
      showModal({
        title: t('pages.EditProfilePage.publicProfileTitle'),
        text: t('pages.EditProfilePage.publicProfileText'),
      });
    } else {
      showModal({
        title: t('pages.EditProfilePage.privateProfileTitle'),
        text: t('pages.EditProfilePage.privateProfileText'),
      });
    }
  };

  const updateProfile = async (values) => {
    try {
      setIsFetching(true);

      const defaultUserData = { ...user?.userProfile };

      let avatar = isAvatarRemoved ? null : user?.userProfile?.avatar;

      if (image) {
        avatar = await uploadAvatar();
      }

      let docResume = user?.userProfile?.userProfileFileResume;
      let videoResume = user?.userProfile?.userProfileVideoResume;

      if (isDocResumeDeleted) {
        docResume = null;
      } else if (resumeFile) {
        const uploadedResumeData = await PublicService.uploadFile({
          file: resumeFile,
        });
        docResume = {
          fileName: uploadedResumeData.displayName,
          url: uploadedResumeData.uri,
          mimeType: uploadedResumeData.mimeType,
        };
      }

      if (isVideoResumeDeleted) {
        videoResume = null;
      } else if (resumeVideo) {
        const uploadedResumeData = await PublicService.uploadVideoFile({
          file: resumeVideo,
        });

        videoResume = {
          fileName: uploadedResumeData.displayName,
          url: uploadedResumeData.uri,
          mimeType: uploadedResumeData.mimeType,
        };
      }

      const prevProfileStatus = user.userProfile?.isPrivate;

      const userProfileExperiences = jobs
        ?.filter((job) => (job.jobTitle || job.companyName) && job.startDate)
        .map((job) => ({
          jobTitle: job.jobTitle,
          company: job.companyName,
          startDate: job.startDate,
          endDate: job.endDate,
        }));

      await UserService.updateMe({
        ...defaultUserData,
        avatar,
        firstName: values?.firstName,
        lastName: values?.lastName,
        phone: values.phone,
        city: location,
        birthDate: getStaticDate(date),
        email,
        userProfileGraduation: { id: education.value, name: education.label },
        userProfileExperiences,
        isPrivate:
          activeProfileOption === t('pages.EditProfilePage.privateMode'),
        userProfileFileResume: docResume,
        userProfileVideoResume: videoResume,
        isFresher: !userProfileExperiences?.length ? true : isFresher,
      });

      refetch();
      showUpdateProfileSuccessModal(
        prevProfileStatus,
        activeProfileOption === t('pages.EditProfilePage.privateMode')
      );
      setResumeFile(null);
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .matches(
            /^[A-Za-z]+$/,
            'First name can only contain alphabetical characters'
          )
          .required(t('pages.EditProfilePage.firstNameRequired')),
        lastName: yup
          .string()
          .trim()
          .matches(
            /^[A-Za-z]+$/,
            'Last name can only contain alphabetical characters'
          )
          .required(t('pages.EditProfilePage.lastNameRequired')),
        phone: yup
          .string()
          .test(
            'validatePhone',
            t('pages.EditProfilePage.phoneInvalid'),
            (value) => {
              try {
                const parsedNumber = parsePhoneNumber(value);
                return parsedNumber.isValid();
              } catch (error) {
                return false;
              }
            }
          )
          .required(t('pages.EditProfilePage.phoneRequired')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      firstName: user?.userProfile?.firstName || '',
      lastName: user?.userProfile?.lastName || '',
      phone: user?.userProfile?.phone || '+90',
    },
    validationSchema,
    onSubmit: async (values) => {
      updateProfile(values);
    },
    enableReinitialize: true,
  });

  const setUserData = useCallback(() => {
    setEmail(user.userName);
    setLocation(user.userProfile?.city);
    setDate(user.userProfile?.birthDate ? user.userProfile?.birthDate : '');
    setEducation({
      value: user.userProfile?.userProfileGraduation?.id,
      label: user.userProfile?.userProfileGraduation?.name,
    });
    setJobs(
      user.userProfile?.userProfileExperiences.map((exp, index) => ({
        id: index,
        jobTitle: exp.jobTitle,
        companyName: exp.company,
        startDate: exp.startDate,
        endDate: exp.endDate,
      }))
    );

    if (user.userProfile?.userProfileExperiences.length) {
      setHasWorkExperience(true);
    } else {
      setHasWorkExperience(false);
    }

    setIsFresher(user?.userProfile?.isFresher);

    if (user.userProfile?.isPrivate) {
      setActiveProfileOption(t('pages.EditProfilePage.privateMode'));
    } else {
      setActiveProfileOption(t('pages.EditProfilePage.openToWork'));
    }
  }, [user, t]);

  const resetForms = () => {
    formik.resetForm();
    setUserData();
  };

  const deleteAccount = useCallback(async () => {
    try {
      await UserService.cancelAccount({ userId: user.id });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    }
  }, [showUnknownErrorModal, user]);

  useEffect(() => {
    if (user) {
      setUserData();
    }
  }, [setUserData, user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!hasProfileOptionsUseEffectRun.current) {
      hasProfileOptionsUseEffectRun.current = true;
      return;
    }

    if (activeProfileOption === t('pages.EditProfilePage.deleteMyProfile')) {
      showModal({
        title: t('pages.EditProfilePage.deleteProfileTitle'),
        text: t('pages.EditProfilePage.deleteProfileText'),
        onConfirm: deleteAccount,
      });
      if (user.userProfile.isPrivate) {
        setActiveProfileOption(t('pages.EditProfilePage.privateMode'));
      } else {
        setActiveProfileOption(t('pages.EditProfilePage.openToWork'));
      }
    }
  }, [activeProfileOption, deleteAccount, showModal, t, user]);

  return (
    <div className={classes.EditProfilePage}>
      {isFetching && !user && <Loader />}
      <div className={classes.container}>
        {!isTabletOrMobile && (
          <div className={classes.header}>
            <NavHeader backIcon />
          </div>
        )}
        <div className={classes.content}>
          <div className={classes.col}>
            <div className={classes.avatarContainer}>
              <Avatar
                avatarUrl={
                  user?.userProfile?.avatar
                    ? `${IMAGES_URL}/avatar/${user?.userProfile?.avatar}`
                    : null
                }
                image={image}
                setImage={setImage}
                setIsAvatarRemoved={setIsAvatarRemoved}
                isAvatarRemoved={isAvatarRemoved}
              />
              <div className={classes.userBlock}>
                <h1 className={classes.userName}>
                  {user?.userProfile?.firstName} {user?.userProfile?.lastName}
                </h1>
                <ProfileDropdown
                  options={profileOptions}
                  currentOption={activeProfileOption}
                  setCurrentOption={setActiveProfileOption}
                />
              </div>
            </div>
            {!isTabletOrMobile && (
              <div className={classes.resumeContainer}>
                <h2>{t('pages.EditProfilePage.myResumes')}</h2>
                <div className={classes.resumes}>
                  <VideoResume
                    file={resumeVideo}
                    setFile={setResumeVideo}
                    resume={user?.userProfile?.userProfileVideoResume}
                    isResumeDeleted={isVideoResumeDeleted}
                    setIsResumeDeleted={setIsVideoResumeDeleted}
                  />
                  <WordResume
                    file={resumeFile}
                    setFile={setResumeFile}
                    resume={user?.userProfile?.userProfileFileResume}
                    isResumeDeleted={isDocResumeDeleted}
                    setIsResumeDeleted={setIsDocResumeDeleted}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classNames(classes.col, classes.flexOne)}>
            <div className={classes.mainInfoForm}>
              <MainInfoForm
                email={email}
                formik={formik}
                hasTriedToSubmit={hasTriedToSubmit}
              />
            </div>
            {isTabletOrMobile && (
              <div className={classes.resumeContainer}>
                <h2>{t('pages.EditProfilePage.myResumes')}</h2>
                <div className={classes.resumes}>
                  <VideoResume
                    file={resumeVideo}
                    setFile={setResumeVideo}
                    resume={user?.userProfile.userProfileVideoResume}
                    isResumeDeleted={isVideoResumeDeleted}
                    setIsResumeDeleted={setIsVideoResumeDeleted}
                  />
                  <WordResume
                    file={resumeFile}
                    setFile={setResumeFile}
                    resume={user?.userProfile.userProfileFileResume}
                    isResumeDeleted={isDocResumeDeleted}
                    setIsResumeDeleted={setIsDocResumeDeleted}
                  />
                </div>
              </div>
            )}
            <div className={classes.basicInformationContainer}>
              <AdditionalInfoForm
                location={location}
                setLocation={setLocation}
                date={date}
                setDate={setDate}
                education={education}
                setEducation={setEducation}
                hasWorkExperience={hasWorkExperience}
                setHasWorkExperience={setHasWorkExperience}
                isFresher={isFresher}
                setIsFresher={setIsFresher}
                jobs={jobs}
                setJobs={setJobs}
              />
            </div>
            <div className={classes.buttons}>
              <BlackButton
                width={isTabletOrMobile ? '' : 317}
                height={64}
                onClick={resetForms}
                style={{ borderRadius: 10 }}
              >
                {t('common.cancel')}
              </BlackButton>
              <Button
                width={isTabletOrMobile ? '' : 317}
                height={64}
                style={{ borderRadius: 10, fontSize: 24, fontWeight: 500 }}
                onClick={() => {
                  setHasTriedToSubmit(true);
                  formik.handleSubmit();
                }}
              >
                {t('common.save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
