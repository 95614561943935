import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import BlackButton from '../BlackButton';
import Button from '../../../Button';
import Reschedule from '../Reschedule';

import classes from './styles.module.scss';

export default function RescheduleWithButtons({
  title,
  onSubmit,
  onBackButtonClick,
}) {
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const [rescheduledTime, setRescheduledTime] = useState(null);
  const [rescheduledTimePeriod, setRescheduledTimePeriod] = useState('AM');

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const resetForm = () => {
    setRescheduledDate(null);
    setRescheduledTime(null);
    setRescheduledTimePeriod('AM');
  };

  return (
    <div className={classes.RescheduleWithButtons}>
      <div>
        <h3 className={classes.heading}>{title}</h3>
        <Reschedule
          onBackButtonClick={onBackButtonClick}
          rescheduledDate={rescheduledDate}
          setRescheduledDate={setRescheduledDate}
          rescheduledTime={rescheduledTime}
          setRescheduledTime={setRescheduledTime}
          rescheduledTimePeriod={rescheduledTimePeriod}
          setRescheduledTimePeriod={setRescheduledTimePeriod}
        />
      </div>
      <div className={classes.buttons}>
        <BlackButton
          onClick={resetForm}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
        >
          {t('common.clear')}
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          jobApplicationInfo
          onClick={() =>
            onSubmit(rescheduledDate, rescheduledTime, rescheduledTimePeriod)
          }
          disabled={!rescheduledDate || !rescheduledTime}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
