import React, { useState, useEffect, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function SearchBar({
  setSearchTerm,
  onCloseSearchClick,
  width,
  searchName,
  alwaysWhite,
}) {
  const [inputValue, setInputValue] = useState('');
  const [searchHistory, setSearchHistory] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  const timer = useRef();
  const searchBarRef = useRef();

  useOnClickOutside(searchBarRef, onCloseSearchClick);

  const updateSearchHistory = useCallback(
    (sTerm) => {
      if (!sTerm.trim()) {
        return;
      }

      // eslint-disable-next-line no-shadow
      let searchHistory = localStorage.getItem(searchName);

      if (!searchHistory) {
        searchHistory = JSON.stringify([sTerm]);
      } else {
        searchHistory = JSON.parse(searchHistory);

        if (searchHistory.includes(sTerm)) {
          return;
        }

        searchHistory.unshift(sTerm);

        if (searchHistory.length > 5) {
          searchHistory.pop();
        }

        searchHistory = JSON.stringify(searchHistory);
      }

      localStorage.setItem(searchName, searchHistory);
    },
    [searchName]
  );

  const getSearchHistory = useCallback(() => {
    // eslint-disable-next-line no-shadow
    const searchHistory = localStorage.getItem(searchName);

    if (!searchHistory) {
      setSearchHistory([]);
      return;
    }
    setSearchHistory(JSON.parse(searchHistory));
  }, [searchName]);

  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setSearchTerm(inputValue);
      updateSearchHistory(inputValue);
      getSearchHistory();
    }, 500);
  }, [getSearchHistory, inputValue, setSearchTerm, updateSearchHistory]);

  useEffect(() => {
    getSearchHistory();
  }, [getSearchHistory, isFocused]);

  return (
    <div
      className={classNames(classes.SearchBar, {
        [classes.alwaysWhite]: alwaysWhite,
      })}
      style={{ width }}
      ref={searchBarRef}
    >
      {inputValue.trim() && (
        <i
          className={classes.closeSearch}
          onClick={() => {
            setSearchTerm('');
            setInputValue('');
            // onCloseSearchClick();
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L1 9"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L9 9"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      )}
      <input
        type="text"
        placeholder={t('common.search')}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 200);
        }}
      />
      {isFocused && searchHistory?.length > 1 ? (
        <ul className={classes.searchHistory}>
          {searchHistory.map((entry) => (
            <li key={entry}>
              <span
                onClick={() => {
                  setInputValue(entry);
                }}
              >
                {entry}
              </span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
