import React from 'react';

import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { MAP_KEY } from '../../../../constants/main';
import classes from './styles.module.scss';

const libraries = [];

export default function Map() {
  const { t } = useTranslation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
    libraries,
  });

  return (
    <div className={classes.Map}>
      <div className={classes.city}>
        {t('components.MyDashboard.JobApplicationInfo.Map.city')}
      </div>
      {isLoaded && (
        <GoogleMap
          zoom={16}
          libraries
          center={{ lat: 41.0108, lng: 28.968 }}
          onClick={() => {}}
          mapContainerClassName={classes.map}
          options={{
            draggable: false,
            scrollwheel: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: {
              position: -1,
            },
          }}
        >
          {/*        {markers.map((marker) => {
            return (
              <Marker
                key={marker.name}
                position={marker.location}
                // label={{
                //   text: `${marker.jobCount}`,
                //   color: 'white',
                //   fontFamily: 'Gilroy-Bold',
                //   fontSize: '18px',
                // }}
              />
            );
          })} */}
        </GoogleMap>
      )}
    </div>
  );
}
