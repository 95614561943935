import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import JobApplicationInfo from '../../components/MyDashboard/JobApplicationInfo';
import Job from '../../components/Jobs/Job';

import { getAuthData } from '../../helpers/authStorage';
import PublicService from '../../services/PublicService';
import classes from './styles.module.scss';
import NoFavorites from './NoFavorites';

export default function MyFavoritesPage() {
  const [isJobApplicationInfoVisible, setIsJobApplicationInfoVisible] =
    useState(false);
  const [isJobApplicationInfoMounted, setIsJobApplicationInfoMounted] =
    useState(false);
  const [currentJobApplicationId, setCurrentJobApplicationId] = useState(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userId } = getAuthData();

  const {
    data: jobApplications,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: PublicService.getJobApplications,
    keepPreviousData: true,
  });

  const showJobApplicationInfo = (jobApplicationId) => {
    setCurrentJobApplicationId(jobApplicationId);
    setIsJobApplicationInfoMounted(true);

    setTimeout(() => {
      setIsJobApplicationInfoVisible(true);
    }, 0);
  };

  const hideJobsApplicationInfo = () => {
    setIsJobApplicationInfoVisible(false);
  };

  const favoriteJobApplications = jobApplications?.filter((jobApp) =>
    jobApp.jobPost.jobPostFavorites?.some((fav) => {
      return fav.userId === +userId;
    })
  );

  const sortedFavoriteJobApplications = favoriteJobApplications?.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  return (
    <div className={classes.MyFavoritesPage}>
      {isFetching && !jobApplications && <Loader />}
      <div className={classes.header}>
        <button
          type="button"
          className={classes.backButton}
          onClick={() => navigate(-1)}
        >
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
              fill="#242833"
            />
          </svg>
        </button>
        <h2>
          {t('components.Header.my')} {t('components.Header.favorites')}
        </h2>
      </div>
      {sortedFavoriteJobApplications?.length ? (
        <>
          <ul className={classes.jobsList}>
            {sortedFavoriteJobApplications?.map((jobApp) => {
              return (
                <li key={jobApp.id}>
                  <Job
                    applied
                    showJobApplicationInfo={showJobApplicationInfo}
                    stretch
                    refetch={refetch}
                    job={jobApp.jobPost}
                    jobApplication={jobApp}
                  />
                </li>
              );
            })}
          </ul>
          {isJobApplicationInfoMounted && (
            <JobApplicationInfo
              isVisible={isJobApplicationInfoVisible}
              currentJobApplication={jobApplications?.find(
                (jobApp) => jobApp.id === currentJobApplicationId
              )}
              hideJobsApplicationInfo={hideJobsApplicationInfo}
              refetch={refetch}
            />
          )}
        </>
      ) : (
        <NoFavorites />
      )}
    </div>
  );
}
