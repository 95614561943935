import React from 'react';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import Lottie from 'lottie-react';

import loaderAnimation from '../../assets/animations/loader.json';
import classes from './styles.module.scss';
import './styles.scss';

export default function Loader({ relative, width }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className={classNames(classes.Loader, {
        [classes.relative]: relative,
      })}
    >
      <Lottie
        animationData={loaderAnimation}
        style={{
          width: width || (isTabletOrMobile ? '70%' : '20%'),
        }}
        loop
      />
    </div>
  );
}
