import React, { useState, useEffect } from 'react';

// import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
// import { useTranslation } from 'react-i18next';

import Messages from './Messages';
// import Assessment from './JobApplicationStatuses/Screen/Assessment';
// import StartDateOrScheduled from './JobApplicationStatuses/Onboard/StartDateOrScheduled';
// import ContactOrShareFeedback from './ContactOrShareFeedback';
// import Offered from './JobApplicationStatuses/Offer/Offered';
// import RejectionMessage from './RejectionMessage';
// import MessageWithButton from './MessageWithButton';
// import Invited from './JobApplicationStatuses/New/Invited';
// import Review from './JobApplicationStatuses/New/Review';
import Status from './Status';
import Header from './Header';
// import SetInterview from './JobApplicationStatuses/Interview/SetInterview';

import { useContentComponent } from './utils/useContentComponent';
import { getAuthData } from '../../../helpers/authStorage';
// import { UiContext } from '../../../context/UiContext';
import classes from './styles.module.scss';

export default function JobApplicationInfo({
  isVisible,
  hideJobsApplicationInfo,
  currentJobApplication,
  refetch,
  initialMode,
}) {
  // const [shouldPreventClosing, setShouldPreventClosing] = useState(false);
  // const [preventClosingReason, setPreventClosingReason] = useState(false);
  const [mode, setMode] = useState('jobApplicationProgress');

  // const navigate = useNavigate();
  //  const { showModal } = useContext(UiContext);

  // const { t } = useTranslation();

  const { userId } = getAuthData();

  const hasMessages =
    !!currentJobApplication?.jobApplicationUserMessages?.length;
  const hasUnreadMessages =
    !!currentJobApplication?.jobApplicationUserMessages?.find(
      (message) => !message.readAt && message.authorId !== +userId
    );

  useEffect(() => {
    if (initialMode) {
      setMode(initialMode);
    }
  }, [initialMode]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isVisible]);

  const toggleMode = () => {
    setMode((currentMode) => {
      if (currentMode === 'jobApplicationProgress') {
        return 'messages';
      }
      return 'jobApplicationProgress';
    });
  };

  /*  const ContentComponent =
    contentComponents[currentJobApplication?.status]?.[
      currentJobApplication?.subStatus
    ]?.component;
 */
  /*   const ContentComponent = contentComponents.Screen?.Requested?.component; */

  const {
    contentComponent: ContentComponent,
    contentComponentProps,
    closeJobApplicationInfo,
  } = useContentComponent(
    currentJobApplication,
    refetch,
    hideJobsApplicationInfo
  );

  /*   const contentComponentProps =
    contentComponents[currentJobApplication?.status]?.[
      currentJobApplication?.subStatus
    ]?.props || {}; */
  /* 
  const contentComponentProps =
    contentComponents.Screen?.Requested?.props || {}; */

  console.log('currentJobApplication', currentJobApplication?.jobPost);
  console.log(currentJobApplication?.status, currentJobApplication?.subStatus);

  let mainContent = null;

  console.log(currentJobApplication);

  if (ContentComponent && mode === 'jobApplicationProgress') {
    mainContent = <ContentComponent {...contentComponentProps} />;
  } else if (mode === 'messages') {
    mainContent = (
      <Messages
        goBack={toggleMode}
        messages={currentJobApplication?.jobApplicationUserMessages}
        refetch={refetch}
        jobApplicationId={currentJobApplication?.id}
      />
    );
  }

  const isJobClosed =
    currentJobApplication?.jobPost.status === 'UnPublished' ||
    currentJobApplication?.subStatus === 'JobClosed';

  return (
    <>
      {isVisible && <div className={classes.backdrop} />}
      <div
        className={classNames(classes.JobApplicationInfo, {
          [classes.visible]: isVisible,
        })}
      >
        <div className={classes.headerContainer}>
          <Header
            hideJobsApplicationInfo={closeJobApplicationInfo}
            title={currentJobApplication?.jobPost.jobTitle}
            location={currentJobApplication?.location?.city?.name}
            jobId={currentJobApplication?.jobPost.id}
            toggleMode={toggleMode}
            hasMessages={hasMessages}
            hasUnreadMessages={hasUnreadMessages}
          />
        </div>
        {!isJobClosed && (
          <div className={classes.statusContainer}>
            <Status
              currentJobApplication={currentJobApplication}
              assessment={currentJobApplication?.jobApplicationAssessments[0]}
              status={currentJobApplication?.status}
              subStatus={currentJobApplication?.subStatus}
              hasAssessment={
                !!currentJobApplication?.jobPost?.assignedAssessments?.length ||
                !!currentJobApplication?.assignedAssessments?.length
              }
            />
          </div>
        )}
        <div className={classes.content}>{mainContent}</div>
      </div>
    </>
  );
}
