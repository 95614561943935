import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  getTimezoneTime,
  getTimezoneDate,
} from '../../../../helpers/timezones';

import InfoField from '../InfoField';

import classes from './styles.module.scss';

export default function ScheduleDetails({ schedule, label }) {
  const { t } = useTranslation();

  const contact = schedule?.users?.length
    ? `${schedule?.users?.[0]?.userProfile.firstName} ${schedule?.users?.[0]?.userProfile.lastName}`
    : schedule?.organizer?.userProfile?.fullName;

  return (
    <div className={classes.ScheduleDetails}>
      <h3>{label}:</h3>
      <div className={classes.info}>
        <div className={classes.row}>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.date'
              )}
              value={moment(
                getTimezoneDate(schedule?.interviewDate)[0] ||
                  getTimezoneDate(schedule?.onboardingDate)[0]
              )?.format('DD/MM/YYYY')}
            />
          </div>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.time'
              )}
              value={getTimezoneTime(schedule?.startTime)}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.type'
              )}
              value={schedule?.type.replace(/([a-z])([A-Z])/g, '$1-$2')}
            />
          </div>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.contact'
              )}
              value={contact}
            />
          </div>
        </div>
        <div className={classes.row}>
          {schedule?.type === 'InPerson' ? (
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.meetingLocation'
              )}
              value={schedule?.customLocation || schedule?.locationName}
            />
          ) : (
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ScheduleDetails.meetingLink'
              )}
              value={schedule?.connectionDetail}
            />
          )}
        </div>
      </div>
    </div>
  );
}
