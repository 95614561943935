import React, { useEffect, useLayoutEffect, useContext } from 'react';

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';

import MyNotificationsPage from '../pages/MyNotificationsPage';
import PolicyPage from '../pages/PolicyPage';
import CookiesPolicy from '../components/CookiesPolicy';
import CompleteApplicationPage from '../pages/CompleteApplicationPage';
import MyFavoritesPage from '../pages/MyFavoritesPage';
import MyDashboardPage from '../pages/MyDashboardPage';
import SignInPage from '../pages/SignInPage';
import DropOffPage from '../pages/DropOffPage';
import EditProfilePage from '../pages/EditProfilePage';
import RecordVideoPage from '../pages/RecordVideoPage';
import ApplyJobPage from '../pages/ApplyJobPage';
import JobPage from '../pages/JobPage';
import JobsPage from '../pages/JobsPage';
import CityJobsPage from '../pages/CityJobsPage';
import LandingPage from '../pages/LandingPage';
import Layout from '../containers/Layout';
import PrivateRoute from '../containers/PrivateRoute';
import NotFound from '../components/NotFound';
import UnderMaintenance from '../components/UnderMaintenance';

import { hasAuthData } from '../helpers/authStorage';
import UiContextProvider, { UiContext } from '../context/UiContext';
import UserService from '../services/UserService';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return null;
};

const CheckUserProfile = () => {
  const isLoggedIn = hasAuthData();
  const { checkIfUserProfileFilled } = useContext(UiContext);

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (user && isLoggedIn) {
      checkIfUserProfileFilled(user);
    }
  }, [user, isLoggedIn, checkIfUserProfileFilled]);

  return null;
};

const RoutesComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UiContextProvider>
          <ScrollToTop />
          <CheckUserProfile />
          <CookiesPolicy />
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <LandingPage />
                </Layout>
              }
            />
            <Route
              path="/jobs"
              element={
                <Layout>
                  <CityJobsPage />
                </Layout>
              }
            />
            <Route
              path="/jobs/city/:cityId/:cityName"
              element={
                <Layout>
                  <JobsPage />
                </Layout>
              }
            />
            <Route
              path="/jobs/:jobId"
              element={
                <Layout>
                  <JobPage />
                </Layout>
              }
            />
            <Route
              path="/jobs/:jobId/apply"
              element={
                <PrivateRoute>
                  <Layout>
                    <ApplyJobPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/jobs/:jobId/apply/video"
              element={
                <PrivateRoute>
                  <Layout>
                    <RecordVideoPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/jobs/:jobId/apply/info"
              element={
                <PrivateRoute>
                  <Layout>
                    <CompleteApplicationPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Layout>
                    <EditProfilePage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/drop-off"
              element={
                <Layout>
                  <DropOffPage />
                </Layout>
              }
            />
            <Route
              path="/sign-in"
              element={
                <Layout>
                  <SignInPage />
                </Layout>
              }
            />
            <Route
              path="/my-dashboard"
              element={
                <PrivateRoute>
                  <Layout>
                    <MyDashboardPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/favorites"
              element={
                <PrivateRoute>
                  <Layout>
                    <MyFavoritesPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/policy"
              element={
                <Layout>
                  <PolicyPage />
                </Layout>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Layout>
                    <MyNotificationsPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="/502"
              element={
                <Layout>
                  <UnderMaintenance />
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <Layout>
                  <NotFound />
                </Layout>
              }
            />
          </Routes>
        </UiContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default RoutesComponent;
