/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import VideoProgress from './VideoProgress';
import MainButton from './MainButton';
import ViewSample from './ViewSample';
import Timer from './Timer';

import classes from './styles.module.scss';
import AdditionalButton from './AdditionalButton';
import VideoShortWindow from './VideoShortWindow';

export default function Video({
  videoPlayerRef,
  mode,
  countdownTimerValue,
  startVideoRecording,
  isVideoRecording,
  stopVideoRecording,
  isViewSampleVisible,
  setIsViewSampleVisible,
  file,
  pauseVideoRecording,
  isVideoRecordingPaused,
  resumeVideoRecording,
  retakeVideo,
  useVideo,
  canvasRef,
  takePhoto,
  image,
  retakePhoto,
  usePhoto,
  step,
  videoPreviewRef,
  showFileBrowser,
  flipMobileCamera,
  videoStreamResolution,
}) {
  const [isVideoPreviewPlaying, setIsVideoPreviewPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isVideoTooShort, setIsVideoTooShort] = useState(false);

  const isReviewingRecordedVideo = !!file;

  const videoPreviewPausePosition = useRef();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const playPreviewVideo = () => {
    setIsVideoPreviewPlaying(true);
  };

  const pausePreviewVideo = useCallback(() => {
    // videoPreviewRef.current.pause();
    videoPreviewPausePosition.current = videoPreviewRef.current.currentTime;
    setIsVideoPreviewPlaying(false);
  }, [videoPreviewRef]);

  useEffect(() => {
    if (isVideoPreviewPlaying) {
      if (Number.isFinite(videoPreviewPausePosition.current)) {
        videoPreviewRef.current.currentTime =
          videoPreviewPausePosition.current || 0;
      }
      videoPreviewRef.current.play();
    } else {
      //
      if (videoPreviewRef.current) {
        videoPreviewRef.current.pause();
        videoPreviewRef.current.currentTime =
          videoPreviewPausePosition.current || 0;
      }
    }
  }, [isVideoPreviewPlaying, videoPreviewRef]);

  // There is a bug in Chrome that causes the browser to return Infinity instead of the correct video.duration. This 'fix' should bypass the bug and save correct video duration in state for future use.
  useEffect(() => {
    if (videoPreviewRef.current) {
      videoPreviewRef.current.currentTime = Number.MAX_SAFE_INTEGER;
      setTimeout(() => {
        if (!videoPreviewRef.current) {
          return;
        }
        videoPreviewRef.current.currentTime = 0.1;
        setVideoDuration(videoPreviewRef.current.duration);
      }, 1000);
    }
  }, [videoPreviewRef, file]);

  useEffect(() => {
    const resetVideo = () => {
      videoPreviewRef.current.currentTime = 0;
      videoPreviewPausePosition.current = 0;
      setIsVideoPreviewPlaying(false);
    };

    pausePreviewVideo.current = 0;

    videoPreviewRef.current?.addEventListener('ended', resetVideo);

    return () => {
      videoPreviewRef.current?.removeEventListener('ended', resetVideo);
    };
  }, [file, pausePreviewVideo, videoPreviewRef]);

  useEffect(() => {
    setVideoDuration(0);
  }, [file]);

  useEffect(() => {
    if (!file || isVideoRecording || !step?.duration || videoDuration <= 0) {
      setIsVideoTooShort(false);
      return;
    }
    if (step?.duration * 0.33 > videoDuration && !isVideoRecording) {
      setIsVideoTooShort(true);
    } else {
      setIsVideoTooShort(false);
    }
  }, [file, isVideoRecording, step?.duration, videoDuration]);

  let frameContent;

  if (mode === 'preRecord') {
    frameContent = (
      <div className={classes.positionFace}>
        <p>{t('components.RecordVideo.Video.positionFace')}</p>
      </div>
    );
  } else if (mode === 'startingRecord') {
    frameContent = (
      <div className={classes.countdownTimer}>{countdownTimerValue}</div>
    );
  }

  let mediaContent;

  if (file) {
    mediaContent = (
      <video key={1} ref={videoPreviewRef} src={URL.createObjectURL(file)} />
    );
  } else if (image) {
    mediaContent = (
      <img alt="Selfie" key={3} src={URL.createObjectURL(image)} />
    );
  } else {
    mediaContent = <video key={2} ref={videoPlayerRef} muted />;
  }

  const isFlipCameraButtonVisible = isTabletOrMobile && mode !== 'recording';

  // width={321} height={723}

  return (
    <div className={classes.Video}>
      <canvas
        ref={canvasRef}
        width={videoStreamResolution.width}
        height={videoStreamResolution.height}
      />
      {isVideoTooShort && !isTabletOrMobile && (
        <VideoShortWindow
          retakeVideo={retakeVideo}
          handleClose={() => setIsVideoTooShort(false)}
        />
      )}
      {isViewSampleVisible && (
        <ViewSample close={() => setIsViewSampleVisible(false)} file={file} />
      )}
      {mediaContent}
      <div className={classes.overlay}>
        {step?.duration && (
          <Timer
            stopVideoRecording={stopVideoRecording}
            isVideoRecording={isVideoRecording}
            time={
              isReviewingRecordedVideo
                ? Math.round(videoDuration)
                : step.duration
            }
            isVideoRecordingPaused={isVideoRecordingPaused}
          />
        )}
        {!file && !image && <div className={classes.frame}>{frameContent}</div>}
        <div className={classes.buttons}>
          {isFlipCameraButtonVisible && (
            <button
              type="button"
              className={classes.flipCameraButton}
              onClick={flipMobileCamera}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="19.5"
                  fill="#C4C4C4"
                  fillOpacity="0.2"
                  stroke="white"
                />
                <g clipPath="url(#clip0_1019_33557)">
                  <path
                    d="M9.17211 10.9065C9.11818 10.4618 9.42812 10.0576 9.8728 10.0036C10.3175 9.94973 10.7217 10.2597 10.7756 10.7043L11.2607 14.4639C13.0934 11.7419 16.1926 10.0575 19.5344 10.0575C23.7926 10.0575 27.5791 12.766 28.967 16.7816C29.1152 17.1993 28.8862 17.6575 28.4685 17.8057C28.3876 17.8327 28.2933 17.8461 28.199 17.8461C27.8621 17.8461 27.5521 17.6305 27.4309 17.3071C26.272 13.9383 23.0919 11.6745 19.5345 11.6745C16.6239 11.6745 13.9154 13.2107 12.4062 15.6631L16.4487 15.205C16.8934 15.1511 17.2976 15.4745 17.3381 15.9192C17.3785 16.3639 17.0685 16.7681 16.6239 16.8086L10.8026 17.4689C10.7756 17.4689 10.7352 17.4689 10.7083 17.4689C10.304 17.4689 9.95364 17.1724 9.91322 16.7682L9.17211 10.9065ZM29.1961 21.727L23.3748 22.3872C22.9301 22.4412 22.6067 22.8319 22.6606 23.2766C22.7145 23.7213 23.1053 24.0447 23.55 23.9908L27.067 23.6C25.6925 26.4972 22.7684 28.3702 19.5209 28.3702C15.8692 28.3702 12.5813 25.9312 11.5302 22.4412C11.3954 22.01 10.9508 21.7674 10.5196 21.9021C10.0884 22.0368 9.84584 22.4816 9.98055 22.9128C10.5869 24.9206 11.8536 26.7262 13.5245 27.9929C15.2628 29.3 17.338 30.0006 19.5209 30.0006C23.5096 30.0006 27.0805 27.629 28.657 24.0312L29.223 28.5184C29.2769 28.9226 29.6273 29.2191 30.018 29.2191C30.0584 29.2191 30.0854 29.2191 30.1258 29.2191C30.5705 29.1651 30.8804 28.7609 30.8265 28.3162L30.0719 22.4546C30.0315 21.9965 29.6408 21.6865 29.1961 21.727Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1019_33557">
                    <rect
                      width="21.6667"
                      height="21.6667"
                      fill="white"
                      transform="matrix(1 0 0 -1 9.16602 30.8325)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
          {!isVideoTooShort && (
            <>
              <MainButton
                isVideoRecording={isVideoRecording}
                isVideoRecordingPaused={isVideoRecordingPaused}
                isVideoPreviewPlaying={isVideoPreviewPlaying}
                file={file}
                startVideoRecording={startVideoRecording}
                stopVideoRecording={stopVideoRecording}
                resumeVideoRecording={resumeVideoRecording}
                playPreviewVideo={playPreviewVideo}
                pausePreviewVideo={pausePreviewVideo}
                mode={mode}
                takePhoto={takePhoto}
              />
              <AdditionalButton
                stopVideoRecording={stopVideoRecording}
                pauseVideoRecording={pauseVideoRecording}
                isVideoRecording={isVideoRecording}
                isVideoRecordingPaused={isVideoRecordingPaused}
                mode={mode}
                image={image}
                showFileBrowser={showFileBrowser}
              />
            </>
          )}
        </div>
        {file && (
          <VideoProgress
            videoDuration={videoDuration}
            videoPreviewRef={videoPreviewRef}
            isVideoPreviewPlaying={isVideoPreviewPlaying}
            videoPreviewPausePosition={videoPreviewPausePosition}
          />
        )}
        {(file || image) && !isVideoTooShort && (
          <div className={classes.readyButtons}>
            <button
              type="button"
              className={classes.retakeButton}
              onClick={file ? retakeVideo : retakePhoto}
            >
              {t('components.RecordVideo.Video.retake')}
            </button>
            <button
              type="button"
              className={classes.useButton}
              onClick={file ? useVideo : usePhoto}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9"
                  cy="9"
                  r="7.5"
                  fill="#D52D34"
                  stroke="white"
                  strokeWidth="1.2"
                />
                <path
                  d="M5.66634 9L8.16634 11.5L12.333 6.5"
                  stroke="white"
                  strokeWidth="1.2"
                />
              </svg>
              <span>{t('components.RecordVideo.Video.use')}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
