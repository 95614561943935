import React from 'react';

import { useTranslation } from 'react-i18next';

import Answer from './Answer';

import classes from './styles.module.scss';

export default function Question({
  question,
  index,
  toggleAnswer,
  questionAnswers,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.Question}>
      <h4 className={classes.title}>
        Q{index}. &nbsp;{question.name}
      </h4>
      <div className={classes.info}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Quiz.Question.pickOneText.0'
        )}{' '}
        {question.assessmentQuestionAnswers?.length}{' '}
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Quiz.Question.pickOneText.1'
        )}
      </div>
      <div className={classes.answers}>
        {question.assessmentQuestionAnswers?.map((answer, i) => {
          return (
            <Answer
              onClick={() => toggleAnswer(question.id, answer.id)}
              isActive={questionAnswers[question.id]?.includes?.(answer.id)}
              key={answer.id}
              answer={answer}
              index={i}
            />
          );
        })}
      </div>
    </div>
  );
}
