/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function SortOrderDropdown({ sortOrder, setSortOrder }) {
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => setAreOptionsVisible(false));

  const sortOptions = [
    t('pages.MyDashboardPage.latest'),
    t('pages.MyDashboardPage.oldest'),
    t('pages.MyDashboardPage.nearest'),
    t('pages.MyDashboardPage.popular'),
  ];

  return (
    <div
      className={classes.SortOrderDropdown}
      onClick={() => setAreOptionsVisible((prevState) => !prevState)}
      ref={dropdownRef}
    >
      <div
        className={classNames(classes.selector, {
          [classes.open]: areOptionsVisible,
        })}
      >
        {sortOrder} <div className={classes.arrow} />
      </div>
      {areOptionsVisible && (
        <ul className={classes.options}>
          {sortOptions.map((option) => (
            <li
              className={classNames({
                [classes.active]: sortOrder === option,
              })}
              onClick={() => setSortOrder(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
