import React from 'react';

import classNames from 'classnames';

import galleryIcon from '../../../../assets/images/gallery.svg';
import classes from './styles.module.scss';

export default function AdditionalButton({
  stopVideoRecording,
  pauseVideoRecording,
  isVideoRecording,
  isVideoRecordingPaused,
  mode,
  image,
  showFileBrowser,
}) {
  const smallStopButton = (
    <button
      type="button"
      className={classNames(classes.smallStopButton, classes.button)}
      onClick={stopVideoRecording}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="20"
          r="19.5"
          fill="#C4C4C4"
          fillOpacity="0.2"
          stroke="white"
        />
        <rect
          x="10.7305"
          y="10.7305"
          width="19"
          height="19"
          rx="3.5"
          fill="white"
          stroke="white"
        />
      </svg>
    </button>
  );

  const pauseButton = (
    <button
      type="button"
      className={classNames(classes.pauseButton, classes.button)}
      onClick={pauseVideoRecording}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="20"
          r="19.5"
          fill="#C4C4C4"
          fillOpacity="0.2"
          stroke="white"
        />
        <rect
          x="13"
          y="10.667"
          width="5.33333"
          height="18.6667"
          rx="1"
          fill="white"
        />
        <rect
          x="22.332"
          y="10.667"
          width="5.33333"
          height="18.6667"
          rx="1"
          fill="white"
        />
      </svg>
    </button>
  );

  const galleryButton = (
    <button
      type="button"
      className={classNames(classes.pauseButton, classes.button)}
      onClick={showFileBrowser}
    >
      <img src={galleryIcon} alt="Browse files" />
    </button>
  );

  let jsx = <></>;

  if (isVideoRecording && !isVideoRecordingPaused) {
    jsx = pauseButton;
  } else if (isVideoRecording && isVideoRecordingPaused) {
    jsx = smallStopButton;
  } else if (mode === 'prePhoto' && !image) {
    jsx = galleryButton;
  }
  return jsx;
}
