/* eslint-disable no-shadow */
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { format } from 'timeago.js';
import Truncate from 'react-text-truncate';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getAuthData } from '../../../helpers/authStorage';
import { UiContext } from '../../../context/UiContext';
import PublicService from '../../../services/PublicService';
import classes from './styles.module.scss';

const defaultFavoritesIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H11C13.8284 1 15.2426 1 16.1213 1.87868C17 2.75736 17 4.17157 17 7V13.8276C17 16.5109 17 17.8525 16.1557 18.2629C15.3114 18.6733 14.2565 17.8444 12.1465 16.1866L11.4713 15.656C10.2849 14.7239 9.69173 14.2578 9 14.2578C8.30827 14.2578 7.71509 14.7239 6.52871 15.656L5.85346 16.1866C3.74355 17.8444 2.68859 18.6733 1.84429 18.2629C1 17.8525 1 16.5109 1 13.8276V7Z"
      fill="#C1C5D6"
      stroke="#C1C5D6"
      strokeWidth="2"
    />
  </svg>
);

const activeFavoritesIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H11C13.8284 1 15.2426 1 16.1213 1.87868C17 2.75736 17 4.17157 17 7V13.8276C17 16.5109 17 17.8525 16.1557 18.2629C15.3114 18.6733 14.2565 17.8444 12.1465 16.1866L11.4713 15.656C10.2849 14.7239 9.69173 14.2578 9 14.2578C8.30827 14.2578 7.71509 14.7239 6.52871 15.656L5.85346 16.1866C3.74355 17.8444 2.68859 18.6733 1.84429 18.2629C1 17.8525 1 16.5109 1 13.8276V7Z"
      fill="#D52D34"
      stroke="#D52D34"
      strokeWidth="2"
    />
  </svg>
);

export default function Job({
  job,
  reference,
  refetch,
  stretch,
  applied,
  showJobApplicationInfo,
  jobApplication,
  showMessages,
  noFavorite,
}) {
  const { userId } = getAuthData();

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isFavorited = job.jobPostFavorites?.some((fav) => {
    return fav.userId === +userId;
  });

  const hasStartDate = !!jobApplication?.jobApplicationJoinings.length;
  const isStartDateCanceled =
    jobApplication?.status === 'Onboard' &&
    jobApplication?.subStatus === 'Canceled' &&
    jobApplication?.jobApplicationOnboardings?.length;

  const hasAssessment = !!jobApplication?.assignedAssessments.length;

  const hasMessages = !!jobApplication?.jobApplicationUserMessages.length;
  const hasUnreadMessages = !!jobApplication?.jobApplicationUserMessages.find(
    (message) => !message.readAt && message.authorId !== +userId
  );
  const viewedJobs = JSON.parse(localStorage.getItem('viewedJobs'));
  const hasBeenViewed =
    job?.jobPostViews?.find((view) => view.userId === +userId) ||
    viewedJobs?.includes(String(job.id));
  const jobClosed = jobApplication?.jobPost?.status === 'UnPublished';

  const createAppliedButtonLabel = (
    status,
    subStatus,
    hasStartDate,
    isStartDateCanceled,
    hasAssessment,
    jobClosed
  ) => {
    if (jobClosed) {
      return t('components.Jobs.Job.labels.Job Closed');
    }

    const labels = {
      Invited: {
        Pending: t('components.Jobs.Job.labels.Review Invitation'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
      },
      New: {
        Accepted: hasAssessment
          ? t('components.Jobs.Job.labels.Assessment Pending')
          : t('components.Jobs.Job.labels.Under Review'),
        Pending: t('components.Jobs.Job.labels.Under Review'),
        Rejected: t('components.Jobs.Job.labels.Not Selected'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
      },
      Screen: {
        Pending: t('components.Jobs.Job.labels.Assessment Pending'),
        Rejected: t('components.Jobs.Job.labels.Not Selected'),
        Requested: t('components.Jobs.Job.labels.Assessment Pending'),
        Passed: t('components.Jobs.Job.labels.Assessment Complete'),
        NoShow: t('components.Jobs.Job.labels.Application abandoned'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
        Failed: t('components.Jobs.Job.labels.Not Selected'),
      },
      Interview: {
        ChangeRequested: t('components.Jobs.Job.labels.Re-schedule Interview'),
        Pending: hasAssessment
          ? t('components.Jobs.Job.labels.Assessment Complete')
          : t('components.Jobs.Job.labels.Under Review'),
        Scheduled: t('components.Jobs.Job.labels.Confirm Interview'),
        Rescheduled: t('components.Jobs.Job.labels.Re-confirm Interview'),
        Rejected: t('components.Jobs.Job.labels.Not Selected'),
        Canceled: t('components.Jobs.Job.labels.Interview Canceled'),
        Accepted: t('components.Jobs.Job.labels.Interview Accepted'),
        Passed: t('components.Jobs.Job.labels.Interview Complete'),
        NoShow: t('components.Jobs.Job.labels.Application abandoned'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
        Declined: t('components.Jobs.Job.labels.Withdrawn'),
      },
      Offer: {
        Pending: t('components.Jobs.Job.labels.Interview Complete'),
        Rejected: t('components.Jobs.Job.labels.Not Selected'),
        Countered: t('components.Jobs.Job.labels.Offer Countered'),
        NoShow: t('components.Jobs.Job.labels.Application abandoned'),
        Accepted: t('components.Jobs.Job.labels.Offer Accepted'),
        Declined: t('components.Jobs.Job.labels.Offer Declined'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
      },
      Onboard: {
        Pending: t('components.Jobs.Job.labels.Offer Accepted'),
        Scheduled: t('components.Jobs.Job.labels.Confirm Onboarding'),
        Rejected: t('components.Jobs.Job.labels.Not Selected'),
        ChangeRequested: hasStartDate
          ? t('components.Jobs.Job.labels.Re-schedule Start Date')
          : t('components.Jobs.Job.labels.Re-schedule Onboarding'),
        Accepted: t('components.Jobs.Job.labels.Onboarding Accepted'),
        Onboarded: t('components.Jobs.Job.labels.Onboarded'),
        StartDateSet: t('components.Jobs.Job.labels.Confirm Start Date'),
        StartConfirmed: t('components.Jobs.Job.labels.Start date Accepted'),
        Canceled: isStartDateCanceled
          ? t('components.Jobs.Job.labels.Start Date Onhold')
          : t('components.Jobs.Job.labels.Onboarding Onhold'),
        NoShow: t('components.Jobs.Job.labels.Application abandoned'),
        StartDeclined: t('components.Jobs.Job.labels.Start Date Declined'),
        Withdrawn: t('components.Jobs.Job.labels.Withdrawn'),
        Joined: t('components.Jobs.Job.labels.Joined'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
        Declined: t('components.Jobs.Job.labels.Onboarding Declined'),
      },
      JobClosed: {
        Pending: t('components.Jobs.Job.labels.Job Closed'),
        Rejected: t('components.Jobs.Job.labels.Job Closed'),
        Withdrawn: t('components.Jobs.Job.labels.Job Closed'),
        Scheduled: t('components.Jobs.Job.labels.Job Closed'),
        Accepted: t('components.Jobs.Job.labels.Job Closed'),
        Passed: t('components.Jobs.Job.labels.Job Closed'),
        NoShow: t('components.Jobs.Job.labels.Job Closed'),
        Declined: t('components.Jobs.Job.labels.Job Closed'),
        Onboarded: t('components.Jobs.Job.labels.Job Closed'),
        StartDateSet: t('components.Jobs.Job.labels.Job Closed'),
        StartConfirmed: t('components.Jobs.Job.labels.Job Closed'),
        StartDeclined: t('components.Jobs.Job.labels.Job Closed'),
        Canceled: t('components.Jobs.Job.labels.Job Closed'),
        ChangeRequested: t('components.Jobs.Job.labels.Job Closed'),
        Countered: t('components.Jobs.Job.labels.Job Closed'),
        Requested: t('components.Jobs.Job.labels.Job Closed'),
        Rescheduled: t('components.Jobs.Job.labels.Job Closed'),
        Joined: t('components.Jobs.Job.labels.Job Closed'),
        JobClosed: t('components.Jobs.Job.labels.Job Closed'),
      },
    };

    console.log(status, subStatus);

    const appliedButtonLabel =
      labels[status]?.[subStatus] || t('components.Jobs.Job.labels.See Offer');

    return appliedButtonLabel;
  };
  /* console.log(
    jobApplication?.jobApplicationUserMessages.length
      ? jobApplication?.jobApplicationUserMessages
      : ''
  ); */

  let buttonLabel =
    jobApplication && jobApplication?.subStatus
      ? createAppliedButtonLabel(
          jobApplication?.status,
          jobApplication?.subStatus,
          hasStartDate,
          isStartDateCanceled,
          hasAssessment,
          jobClosed
        )
      : createAppliedButtonLabel(
          job.jobApplications[0]?.status,
          job.jobApplications[0]?.subStatus,
          hasStartDate,
          isStartDateCanceled,
          hasAssessment,
          jobClosed
        );

  // Check if assessment has expired
  if (buttonLabel === t('components.Jobs.Job.labels.Assessment Pending')) {
    const assessment = jobApplication?.flowQForms?.[0];

    if (assessment) {
      const daysLeft = moment(assessment?.expiresAt).diff(moment(), 'days');
      if (daysLeft <= 0) {
        buttonLabel = t('components.Jobs.Job.labels.Application abandoned');
      }
    }
  }

  const isRedButton =
    buttonLabel === t('components.Jobs.Job.labels.Not Selected') ||
    buttonLabel.toLowerCase().includes('decline') ||
    buttonLabel === t('components.Jobs.Job.labels.Application abandoned');

  const hasApplied =
    applied ||
    (job.jobApplications[0] && job.jobApplications[0].status !== 'Draft');

  return (
    <Link
      className={classNames(classes.Job, {
        [classes.stretch]: stretch,
      })}
      ref={reference}
      to={`/jobs/${job.id}`}
      onClick={(event) => {
        if (applied) {
          event.preventDefault();
          showJobApplicationInfo(jobApplication?.id);
        }
      }}
    >
      <header className={classes.header}>
        <h1>{job.jobTitle}</h1>
        <button
          style={noFavorite ? { display: 'none' } : {}}
          type="button"
          className={classes.favoritesButton}
          onClick={async (event) => {
            try {
              setIsFetching(true);

              event.preventDefault();
              event.stopPropagation();
              if (!userId) {
                return;
              }
              if (isFavorited) {
                await PublicService.removeJobFromFavorites({ jobId: job.id });
              } else {
                await PublicService.addJobToFavorites({ jobId: job.id });
              }
              await refetch();
            } catch (error) {
              console.log(error);
            } finally {
              setIsFetching(false);
            }
          }}
        >
          {isFavorited ? activeFavoritesIcon : defaultFavoritesIcon}
        </button>
      </header>
      <div className={classes.location}>
        {job.locationCityDistrict?.name &&
          `${job.locationCityDistrict?.name}, `}
        {job.locationCity?.name}
      </div>
      <div className={classes.details}>
        {job.jobTypes?.length ? (
          <span className={classes.jobType}>
            {job.jobTypes?.map((type) => type.name).join(', ')}
          </span>
        ) : null}
        {job.workExperience && (
          <span className={classes.workExperience}>
            {job.workExperience?.name} Years
          </span>
        )}
        {job?.jobPostTags.length ? (
          <div className={classes.badge}>{job?.jobPostTags[0].name}</div>
        ) : null}
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.description}>
          <Truncate
            line={2}
            text={job.jobDescription}
            truncateText="…"
            element="span"
          />
        </div>
      </div>
      <div className={classes.skills}>
        {job.skills?.slice(0, 3).map((skill) => {
          return (
            <div key={skill.id} className={classes.skill}>
              {skill.name}
            </div>
          );
        })}
        {job.skills?.length > 3 ? (
          <div className={classes.skill}>+ more</div>
        ) : null}
      </div>
      <footer className={classes.footer}>
        <div className={classes.leftSide}>
          <div className={classes.createdAt}>
            {format(jobApplication?.createdAt || job.createdAt)}
          </div>
          {applied && hasMessages ? (
            <div
              className={classNames(classes.messages, {
                [classes.unread]: hasUnreadMessages,
              })}
              role="button"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                showMessages(jobApplication?.id);
              }}
              tabIndex={0}
            />
          ) : null}
        </div>
        {hasApplied ? (
          <button
            className={classNames(classes.offerButton, {
              [classes.red]: isRedButton,
            })}
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              showJobApplicationInfo(jobApplication?.id);
            }}
          >
            {buttonLabel}
          </button>
        ) : (
          <div
            className={classes.status}
            style={{
              visibility:
                !hasBeenViewed &&
                moment(new Date()).diff(
                  moment(jobApplication?.createdAt || job.createdAt),
                  'days'
                ) > 5
                  ? 'hidden'
                  : 'visible',
            }}
          >
            {hasBeenViewed
              ? t('components.Jobs.Job.labels.Viewed')
              : t('components.Jobs.Job.labels.New')}
          </div>
        )}
      </footer>
    </Link>
  );
}
