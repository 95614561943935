class NotificationStorage {
  constructor() {
    const initialData = localStorage.getItem('readNotificationIds');

    if (initialData) {
      try {
        this._readNotificationIds = JSON.parse(initialData);
      } catch (e) {
        this._readNotificationIds = [];
      }
    } else {
      this._readNotificationIds = [];
    }
  }

  addNotificationId(id) {
    this._readNotificationIds.push(id);
    localStorage.setItem(
      'readNotificationIds',
      JSON.stringify(this._readNotificationIds)
    );
  }

  get readNotificationIds() {
    return this._readNotificationIds;
  }
}

const notificationStorage = new NotificationStorage();

export default notificationStorage;
