import React, { useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import VideoResume from './VideoResume';
import DocResume from './DocResume';

import { UiContext } from '../../context/UiContext';
// import PublicService from '../../services/PublicService';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';

export default function PreviousJobApplicationModal({ show, handleClose }) {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const { setPreviousResume } = useContext(UiContext);

  const { t } = useTranslation();

  /*   const { data: previousJobApplications } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: PublicService.getJobApplications,
    // keepPreviousData: true,
  });
 */
  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  /*   const lastJobApplicationWithVideo = previousJobApplications
    ?.filter((jobApp) => jobApp.jobApplicationVideos.length)
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.[0];

  const lastJobApplicationWithDoc = previousJobApplications
    ?.filter((jobApp) => jobApp.jobApplicationAttachments.length)
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.[0]; */

  const useVideoResume = () => {
    setPreviousResume({
      type: 'video',
      jobId,
      videos: [user?.userProfile?.userProfileVideoResume],
    });
    navigate(`/jobs/${jobId}/apply/video?previous=true`);
  };
  const useDocResume = () => {
    setPreviousResume({
      type: 'doc',
      jobId,
      doc: user?.userProfile?.userProfileFileResume,
    });
    navigate(`/jobs/${jobId}/apply/info?previous=true`);
  };

  return (
    <Modal
      show={show}
      centered
      className={classes.PreviousJobApplicationModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h1>
          {user?.userProfile?.userProfileFileResume ||
          user?.userProfile?.userProfileVideoResume
            ? t('modals.PreviousJobApplicationModal.pickResumeToUse')
            : t('modals.PreviousJobApplicationModal.pickPreviousApplication')}
        </h1>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <div className={classes.resumes}>
          <VideoResume
            onSelect={useVideoResume}
            videos={[user?.userProfile?.userProfileVideoResume]}
            createdAt={user?.userProfile?.userProfileVideoResume?.createdAt}
          />
          <DocResume
            onSelect={useDocResume}
            doc={user?.userProfile?.userProfileFileResume}
            createdAt={user?.userProfile?.userProfileFileResume?.createdAt}
          />
        </div>
      </div>
    </Modal>
  );
}
