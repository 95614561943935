import React from 'react';

import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
// eslint-disable-next-line camelcase
import { atcb_action } from 'add-to-calendar-button';
import { useTranslation } from 'react-i18next';

import { getTime } from '../../helpers/timezones';
import Button from '../Button';

import classes from './styles.module.scss';

/* const config = {
  name: '[Reminder] Test the Add to Calendar Button',
  description:
    'Check out the maybe easiest way to include Add to Calendar Buttons to your web projects:[br]→ [url]https://add-to-calendar-button.com/',
  startDate: '2023-06-08',
  startTime: '10:15',
  endTime: '23:30',
  options: ['Apple', 'Google', 'Outlook.com', 'Yahoo', 'iCal'],
  // timeZone: 'America/Los_Angeles',
}; */

export default function CalendarButton({ schedule, scheduleType }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const date =
    schedule.joiningDate || schedule.onboardingDate || schedule.interviewDate;

  const config = {
    name: scheduleType,
    description: scheduleType,
    startDate: moment(date).format('YYYY-MM-DD'),
    startTime: getTime(schedule.startTime),
    endTime: schedule.endTime,
    options: ['Apple', 'Google', 'Outlook.com', 'Yahoo', 'iCal'],
    location: schedule.locationName,
  };

  return (
    <div className={classes.CalendarButton}>
      <Button
        onClick={() => atcb_action(config)}
        likeDisabled
        height={54}
        width={isTabletOrMobile ? '' : 288}
        style={{ fontWeight: 500, fontSize: 24 }}
      >
        {t('components.CalendarButton.addToCalendar')}
      </Button>
    </div>
  );
}
