import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';
import Success from './Success';
import Quiz from './Quiz';
import MessageWithButton from '../../../MessageWithButton';

// import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import AssessmentCard from './AssessmentCard';
import PublicService from '../../../../../../services/PublicService';

export default function Assessment({
  currentJobApplication,
  hideJobsApplicationInfo,
  preventClosing,
  releaseClosing,
  // refetch,
}) {
  const [mode, setMode] = useState('initial');
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const { t } = useTranslation();

  // const { setIsFetching } = useContext(UiContext);

  const hasFlowQ = !!currentJobApplication?.flowQForms?.length;

  const { data: flowQFormsDetails } = useQuery({
    queryKey: ['flowQFormsDetails', currentJobApplication?.id],
    queryFn: () =>
      PublicService.getFlowQFormsDetails(currentJobApplication?.id),
    enabled: !!currentJobApplication?.id && hasFlowQ,
  });

  const unfinishedAssessmentCount = flowQFormsDetails?.filter(
    (form) => !form.performedAt
  ).length;

  let hasAssesmentExpired = false;

  if (flowQFormsDetails?.some((form) => moment().isAfter(form.expiresAt))) {
    hasAssesmentExpired = true;
  }

  const doLater = () => {
    hideJobsApplicationInfo();
  };

  /* const passJobAssessment = async () => {
    try {
      setIsFetching(true);
      await PublicService.passJobAssessment({
        jobApplicationId: currentJobApplication?.id,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  }; */

  useEffect(() => {
    return () => {
      setMode('initial');
    };
  }, []);

  /* useEffect(() => {
    if (mode === 'success') {
      passJobAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]); */

  console.log(mode);

  const openAssessmentTest = () => {
    const url = selectedAssessment?.url;
    window.open(url, '_blank');
  };

  return (
    <div className={classes.Assessment}>
      {mode === 'initial' && (
        <MessageWithButton
          message={
            hasAssesmentExpired ? (
              <p>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessageExpired.0'
                )}
                <br />
                <br />
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessageExpired.1'
                )}
              </p>
            ) : (
              <p>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessage.0'
                )}
                <br /> <br />{' '}
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessage.1'
                )}{' '}
                <span style={{ color: '#D52D34' }}>
                  {unfinishedAssessmentCount}{' '}
                </span>{' '}
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessage.2'
                )}{' '}
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialMessage.3'
                )}
                <div className={classes.assessments}>
                  {currentJobApplication?.flowQForms?.map((assessment) => (
                    <AssessmentCard
                      flowQDetails={
                        flowQFormsDetails?.find(
                          (form) => form.code === assessment.code
                        ) || {}
                      }
                      key={assessment.key}
                      assessment={assessment}
                      result={currentJobApplication?.flowQResults?.find(
                        (result) => result.code === assessment.code
                      )}
                      selected={assessment === selectedAssessment}
                      setSelectedAssessment={setSelectedAssessment}
                    />
                  ))}
                </div>
              </p>
            )
          }
          buttonLabel={
            hasAssesmentExpired
              ? t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialButtonBrowseJobs'
                )
              : t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.initialButtonStartAssessment'
                )
          }
          onButtonClick={() => {
            openAssessmentTest();
            // setMode('startAssessment');
          }}
          buttonDisabled={!selectedAssessment && !hasAssesmentExpired}
        />
      )}
      {mode === 'startAssessment' && (
        <div className={classes.startAssessmentContainer}>
          <MessageWithButton
            buttonLabel={t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.startAssessmentButtonLetsGo'
            )}
            secondaryButtonLabel={t(
              'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.startAssessmentButtonWillDoLater'
            )}
            onSecondaryButtonClick={doLater}
            onButtonClick={() => setMode('quiz')}
            message={
              <>
                <div>
                  <h3 className={classes.instructionsTitle}>Instructions</h3>
                  <ol className={classes.instructionsList}>
                    <li>
                      lorensupsu lorensupsum lorensupsumlorensups umlorensupsum
                    </li>
                    <li>
                      lorensupsum lorensupsumlorensups umlorensupsu mlorensupsum
                    </li>
                    <li>
                      lorensup sumlorensupsum lorensupsumlorensupsuml orensupsum
                    </li>
                    <li>
                      lore nsupsumloren supsumlorensupsum lor ensupsumlo
                      rensupsum
                    </li>
                  </ol>
                </div>
                <p>
                  {t(
                    'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.startAssessmentDaysRemaining.0'
                  )}{' '}
                  <span className={classes.red}>
                    {currentJobApplication?.assignedAssessments?.limit || 5}{' '}
                  </span>{' '}
                  {t(
                    'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.startAssessmentDaysRemaining.1'
                  )}
                </p>
              </>
            }
          />
        </div>
      )}
      {mode === 'quiz' && (
        <Quiz
          jobId={currentJobApplication.jobPostId}
          setMode={setMode}
          preventClosing={preventClosing}
          releaseClosing={releaseClosing}
        />
      )}
      {mode === 'success' && <Success />}
    </div>
  );
}
