import React from 'react';

import { useTranslation } from 'react-i18next';

import successImage from '../../../../../../../assets/images/success.svg';
import classes from './styles.module.scss';

export default function Success() {
  const { t } = useTranslation();

  return (
    <div className={classes.Success}>
      <img src={successImage} alt="Success" />
      <h3>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Success.title'
        )}
      </h3>
      <p className={classes.subtitle}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Success.subtitle'
        )}
      </p>
      <p className={classes.info}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Success.info'
        )}
      </p>
    </div>
  );
}
