/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import cakeIcon from '../../../../assets/images/cake.svg';
import DropdownSelector from '../DropdownSelector';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const createDatesJsx = (
  date,
  changeDateOnClick,
  scrollDays,
  scrollMonths,
  scrollYears,
  setIsDaysScrollingDisabled,
  clearPrevTouchClientY
) => {
  const prevDay1 = moment(date).subtract(1, 'days');
  const prevDay2 = moment(date).subtract(2, 'days');
  const nextDay1 = moment(date).add(1, 'days');
  const nextDay2 = moment(date).add(2, 'days');

  const dates = [
    {
      date: prevDay2,
      className: classes.prevDay2,
    },
    {
      date: prevDay1,
      className: classes.prevDay1,
    },
    {
      date,
      className: classes.selectedDate,
    },
    {
      date: nextDay1,
      className: classes.nextDay1,
    },
    {
      date: nextDay2,
      className: classes.nextDay2,
    },
  ];

  return dates.map((item) => (
    <div
      className={classNames(item.className, {
        [classes.day]: item.className !== classes.selectedDate,
      })}
      onClick={() => changeDateOnClick(item.date)}
    >
      <span
        className={classes.monthDate}
        onWheel={scrollDays}
        onTouchMove={scrollDays}
        onTouchEnd={clearPrevTouchClientY}
      >
        {moment(item.date).format('DD')}
      </span>
      <span
        className={classes.month}
        onWheel={scrollMonths}
        onMouseEnter={() => setIsDaysScrollingDisabled(true)}
        onMouseLeave={() => setIsDaysScrollingDisabled(false)}
        onTouchMove={scrollMonths}
        onTouchStart={() => setIsDaysScrollingDisabled(true)}
        onTouchEnd={() => {
          setIsDaysScrollingDisabled(false);
          clearPrevTouchClientY();
        }}
      >
        {moment(item.date).format('MMMM')}
      </span>
      <span
        onWheel={scrollYears}
        onMouseEnter={() => setIsDaysScrollingDisabled(true)}
        onMouseLeave={() => setIsDaysScrollingDisabled(false)}
        onTouchMove={scrollYears}
        onTouchStart={() => setIsDaysScrollingDisabled(true)}
        onTouchEnd={() => {
          setIsDaysScrollingDisabled(false);
          clearPrevTouchClientY();
        }}
      >
        {moment(item.date).format('YYYY')}
      </span>
    </div>
  ));
};

export default function CalendarDropdown({ date, setDate }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);
  const [isDaysScrollingDisabled, setIsDaysScrollingDisabled] = useState(false);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const optionsRef = useRef();
  const prevTouchClientY = useRef([]);

  useOnClickOutside(optionsRef, () => setIsOptionsListVisible(false));

  // Prevent scrolling on desktop devices
  const preventScrolling = useCallback(
    (event) => {
      if (isScrollDisabled && !isTabletOrMobile) {
        event.preventDefault();
      }
    },
    [isScrollDisabled, isTabletOrMobile]
  );

  const disableScroll = () => {
    setIsScrollDisabled(true);
  };

  const enableScroll = useCallback(() => {
    setIsScrollDisabled(false);
  }, []);

  // Prevent scrolling on mobile devices
  useEffect(() => {
    if (!isTabletOrMobile) {
      return;
    }

    if (isOptionsListVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOptionsListVisible, isTabletOrMobile]);

  // Clear prevTouchClientY array
  const clearPrevTouchClientY = () => {
    prevTouchClientY.current = [];
  };

  useEffect(() => {
    if (prevTouchClientY.current.length > 2) {
      clearPrevTouchClientY();
    }
  }, [prevTouchClientY]);

  const detectScrollDirection = (event) => {
    const detectWheelDirection = (e) => {
      if (e.deltaY < 0) {
        return 'up';
      }
      if (e.deltaY > 0) {
        return 'down';
      }
    };

    // The lower the threshold the higher sensitivity
    const detectTouchMoveDirection = (e, threshold = 15) => {
      if (
        Math.abs(e.touches[0].clientY - prevTouchClientY.current.at(-1)) <
        threshold
      ) {
        return;
      }

      prevTouchClientY.current.push(e.touches[0].clientY);
      if (e.touches[0].clientY < prevTouchClientY.current.at(-2)) {
        return 'up';
      }
      if (e.touches[0].clientY > prevTouchClientY.current.at(-2)) {
        return 'down';
      }
    };

    if (event.type === 'wheel') {
      return detectWheelDirection(event);
    }
    if (event.type === 'touchmove') {
      return detectTouchMoveDirection(event);
    }
  };

  const scrollDays = (event) => {
    if (isDaysScrollingDisabled) {
      return;
    }

    const direction = detectScrollDirection(event);

    if (direction === 'up') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setDate(date.getDate() - 1);
        return newDate;
      });
    } else if (direction === 'down') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setDate(date?.getDate?.() + 1);
        return newDate;
      });
    }
  };

  const scrollMonths = (event) => {
    const direction = detectScrollDirection(event);

    if (direction === 'up') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setMonth(date?.getMonth?.() - 1);
        return newDate;
      });
    } else if (direction === 'down') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setMonth(date?.getMonth?.() + 1);
        return newDate;
      });
    }
  };

  const scrollYears = (event) => {
    const direction = detectScrollDirection(event);

    if (direction === 'up') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setFullYear(date.getFullYear() - 1);
        return newDate;
      });
    } else if (direction === 'down') {
      setDate((prevDate) => {
        const newDate = new Date(prevDate?.getTime?.());
        newDate.setFullYear(date.getFullYear() + 1);
        return newDate;
      });
    }
  };

  const changeDateOnClick = (momentDate) => {
    setDate(momentDate.toDate());
  };

  useEffect(() => {
    if (isOptionsListVisible && !date) {
      setDate(new Date('1997-01-01'));
    }
  }, [date, isOptionsListVisible, setDate]);

  useEffect(() => {
    window.addEventListener('wheel', preventScrolling, { passive: false });

    return () => {
      window.removeEventListener('wheel', preventScrolling, { passive: false });
    };
  }, [preventScrolling]);

  useEffect(() => {
    if (!isOptionsListVisible) {
      enableScroll();
    }
  }, [enableScroll, isOptionsListVisible]);

  useEffect(() => {
    if (date && typeof date === 'string') {
      setDate((prev) => {
        const [year, month, day] = prev.split('-');
        const newDate = new Date(date);
        newDate.setDate(day);
        newDate.setMonth(month - 1);
        newDate.setFullYear(year);
        return newDate;
      });
    }
  }, [date, setDate]);

  const currentOption = (
    <div className={classes.currentOption}>
      <div className={classes.selectedDate}>
        <span className={classes.monthDate}>{moment(date).format('DD')}</span>
        <span className={classes.month}>{moment(date).format('MMMM')}</span>
        <span>{moment(date).format('YYYY')}</span>
      </div>
    </div>
  );

  return (
    <DropdownSelector
      value={date}
      currentOption={currentOption}
      toggleOptions={() => setIsOptionsListVisible((prevState) => !prevState)}
      isOptionsListVisible={isOptionsListVisible}
      title={t('components.RecordVideo.AdditionalInfoForm.BirthDropdown.birth')}
      icon={cakeIcon}
    >
      {isOptionsListVisible && (
        <div
          className={classes.options}
          onClick={() => setIsOptionsListVisible(false)}
          onMouseEnter={disableScroll}
          onMouseLeave={enableScroll}
          ref={optionsRef}
        >
          <header className={classes.header}>
            <span className={classes.title}>Date of birth</span>
          </header>
          <div className={classes.dates}>
            {createDatesJsx(
              date,
              changeDateOnClick,
              scrollDays,
              scrollMonths,
              scrollYears,
              setIsDaysScrollingDisabled,
              clearPrevTouchClientY
            )}
          </div>
        </div>
      )}
    </DropdownSelector>
  );
}
