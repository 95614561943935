import React, { useState, useEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '../Loader';
import Notification from './Notification';
import JobApplicationInfo from '../MyDashboard/JobApplicationInfo';

import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import PublicService from '../../services/PublicService';

export default function Notifications({ reference, handleClose }) {
  const [isJobApplcationInfoVisible, setIsJobApplicationInfoVisible] =
    useState(false);
  const [currentJobApplication, setCurrentJobApplication] = useState(null);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const {
    data: notifications,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['notifications'],
    queryFn: PublicService.getNotifications,
  });

  const hasUnreadNotifications = notifications?.some(
    (ntfctn) => !ntfctn.readAt
  );

  const readAllNotifications = async () => {
    try {
      setIsFetching(true);
      await PublicService.readAllNotifications();
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const showJobApplicationInfo = (jobApplication) => {
    setCurrentJobApplication(jobApplication);
    setIsJobApplicationInfoVisible(true);
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <div className={classes.Notifications} ref={reference}>
      {isFetching && !notifications && <Loader relative width="40%" />}
      <header className={classes.header}>
        <div className={classes.heading}>
          <h3>{t('components.Notifications.notifications')}</h3>
          {notifications && (
            <div
              className={classNames(classes.unreadCount, {
                [classes.read]: !hasUnreadNotifications,
              })}
            >
              {notifications.length}
            </div>
          )}
        </div>
        {hasUnreadNotifications && (
          <button
            className={classes.readAllButton}
            type="button"
            onClick={readAllNotifications}
          >
            {t('components.Notifications.markAllAsRead')}
          </button>
        )}
      </header>
      <div className={classes.notificationsListContainer}>
        {!notifications || !notifications.length ? (
          <div className={classes.noData}>
            <p>{t('components.Notifications.noNotifications')}</p>
          </div>
        ) : (
          <ul>
            {notifications.map((notification) => (
              <Notification
                showJobApplicationInfo={showJobApplicationInfo}
                key={notification.id}
                notification={notification}
                refetch={refetch}
                handleClose={handleClose}
              />
            ))}
          </ul>
        )}
      </div>
      <JobApplicationInfo
        isVisible={isJobApplcationInfoVisible}
        hideJobsApplicationInfo={() => setIsJobApplicationInfoVisible(false)}
        currentJobApplication={currentJobApplication}
        refetch={refetch}
      />
    </div>
  );
}
