import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import i18n from '../i18n';
import notificationStorage from '../helpers/NotificationStorage';

import useUiContext from './useUiContext';
import { getTime } from '../helpers/timezones';
import PublicService from '../services/PublicService';

const t = i18n.t.bind(i18n);

function createDefaultNotificationData(notification) {
  return {
    title: notification.eventNote,
    text: notification.jobApplication?.jobPost?.jobTitle,
    buttonsData: {
      mainButtonTitle: t('helpers.getNotificationButtonsData.review'),
      secondaryButtonTitle: '',
      onMainButtonClick: () => {
        notificationStorage.addNotificationId(notification.id);
      },
      mainButtonWidth: 112,
    },
    isOutdated: false,
  };
}

function getJobNotificationData(notification, refetchNotifications) {
  const defaultNotificationData = createDefaultNotificationData(notification);

  const notificationData = {
    Invited: {
      Pending: {
        title: t('helpers.getNotificationButtonsData.invitationCreated'),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          mainButtonTitle: t('helpers.getNotificationButtonsData.review'),
        },
      },
    },
    Onboard: {
      Scheduled: {
        title: t(
          'helpers.getNotificationButtonsData.youHaveOnboardingSetForRole',
          {
            role: notification?.jobApplication?.jobPost?.jobTitle,
          }
        ),
        text: `${moment(
          notification.jobApplication?.jobApplicationOnboardings?.[0]
            ?.onboardingDate
        ).format('Do MMM YYYY')} ${getTime(
          notification.jobApplication?.jobApplicationOnboardings?.[0]?.startTime
        )} - ${getTime(
          notification.jobApplication?.jobApplicationOnboardings?.[0]?.endTime
        )}`,
        buttonsData: {
          mainButtonTitle: t('common.accept'),
          secondaryButtonTitle: t('common.decline'),
          onMainButtonClick: async () => {
            try {
              await PublicService.acceptOnboarding({
                jobApplicationId: notification?.jobApplication?.id,
                onboardingId:
                  notification?.jobApplication?.jobApplicationOnboardings?.[0]
                    ?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
          onSecondaryButtonClick: async () => {
            try {
              await PublicService.declineOnboarding({
                jobApplicationId: notification?.jobApplication?.id,
                onboardingId:
                  notification?.jobApplication?.jobApplicationOnboardings?.[0]
                    ?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
        },
      },
      StartDateSet: {
        title: t('helpers.getNotificationButtonsData.youHaveAStartDate'),
        text: `${moment(
          notification.jobApplication?.jobApplicationJoinings?.[0]?.joiningDate
        ).format('Do MMM YYYY')} ${getTime(
          notification.jobApplication?.jobApplicationJoinings?.[0]?.startTime
        )} - ${getTime(
          notification.jobApplication?.jobApplicationJoinings?.[0]?.endTime
        )}`,
        buttonsData: {
          name: 'JoiningScheduled',
          mainButtonTitle: t('common.accept'),
          secondaryButtonTitle: t('common.decline'),
          onMainButtonClick: async () => {
            try {
              await PublicService.acceptJoining({
                jobApplicationId: notification?.jobApplication?.id,
                joiningId:
                  notification?.jobApplication?.jobApplicationJoinings?.[0]?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
          onSecondaryButtonClick: async () => {
            try {
              await PublicService.declineJoining({
                jobApplicationId: notification?.jobApplication?.id,
                joiningId:
                  notification?.jobApplication?.jobApplicationJoinings?.[0]?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
        },
      },
    },
    Interview: {
      Scheduled: {
        title: t(
          'helpers.getNotificationButtonsData.congratulationsYouHaveAJobInterviewForRole',
          {
            role: notification?.jobApplication?.jobPost?.jobTitle,
          }
        ),
        text: `${moment(
          notification.jobApplication?.jobApplicationInterviews?.[0]
            ?.interviewDate
        ).format('Do MMM YYYY')} ${getTime(
          notification.jobApplication?.jobApplicationInterviews?.[0]?.startTime
        )} - ${getTime(
          notification.jobApplication?.jobApplicationInterviews?.[0]?.endTime
        )}`,
        buttonsData: {
          mainButtonTitle: t('common.accept'),
          secondaryButtonTitle: t('common.decline'),
          onMainButtonClick: async () => {
            try {
              await PublicService.acceptInterviewSchedule({
                jobApplicationId: notification?.jobApplication?.id,
                interviewId:
                  notification?.jobApplication?.jobApplicationInterviews?.[0]
                    ?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
          onSecondaryButtonClick: async () => {
            try {
              await PublicService.declineInterviewSchedule({
                jobApplicationId: notification?.jobApplication?.id,
                interviewId:
                  notification?.jobApplication?.jobApplicationInterviews?.[0]
                    ?.id,
              });
              refetchNotifications?.();
            } catch (error) {
              console.log(error);
            }
          },
        },
      },
    },
    Offer: {
      Offered: {
        title: t('helpers.getNotificationButtonsData.recruiterCreatedOffer'),
        text: notification?.jobApplication?.jobPost?.jobTitle,
        buttonsData: {
          mainButtonTitle: t('helpers.getNotificationButtonsData.review'),
        },
      },
    },
  };

  const data =
    notificationData[notification.onEventJobApplicationStatus]?.[
      notification.onEventJobApplicationSubStatus
    ];

  if (data) {
    const isOutdated =
      notification.jobApplication?.status !==
        notification.onEventJobApplicationStatus ||
      notification.jobApplication?.subStatus !==
        notification.onEventJobApplicationSubStatus;

    data.isOutdated = isOutdated;
  } else {
    const isOutdated = notificationStorage.readNotificationIds.includes(
      notification.id
    );

    defaultNotificationData.isOutdated = isOutdated;
  }

  return data || defaultNotificationData;
}

function getNonJobNotificationData(notification, navigate, closePopup) {
  const defaultNotificationData = createDefaultNotificationData(notification);

  console.log(notification);

  const eventKeys = {
    'notitification.job.candidate_created': {
      title: t(
        'helpers.getNotificationButtonsData.Congratulations!! You profile has been created'
      ),
      text: `${moment(notification.createdAt).format('Do MMM YYYY')} ${getTime(
        notification.createdAt
      )}`,
      buttonsData: {
        mainButtonTitle: t('helpers.getNotificationButtonsData.Setup Profile'),
        secondaryButtonTitle: '',
        onMainButtonClick: () => {
          notificationStorage.addNotificationId(notification.id);
          navigate('/profile');
          closePopup();
        },
        mainButtonWidth: 112,
      },
      isOutdated: false,
    },
  };

  return eventKeys[notification.eventKey] || defaultNotificationData;
}

export default function useGetNotificationData(
  notification,
  refetchNotifications
) {
  const navigate = useNavigate();
  const { setIsNotificationsPopupVisible } = useUiContext();

  const closePopup = () => {
    setIsNotificationsPopupVisible(false);
  };

  if (notification?.jobApplication) {
    return getJobNotificationData(notification, refetchNotifications);
  }
  return getNonJobNotificationData(notification, navigate, closePopup);
}
