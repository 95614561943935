import React, { useState, useEffect, useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../Button';
import BlackButton from '../../../../BlackButton';

import { UiContext } from '../../../../../../../context/UiContext';
import AnonymousService from '../../../../../../../services/AnonymousService';
import classes from './styles.module.scss';
import PublicService from '../../../../../../../services/PublicService';
import Question from './Question';

export default function Quiz({
  jobId,
  setMode,
  preventClosing,
  releaseClosing,
}) {
  const [questionAnswers, setQuestionAnswers] = useState({});

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { showUnknownErrorModal, setIsFetching } = useContext(UiContext);

  const { data: job } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => AnonymousService.getJobById({ jobId }),
  });

  const { data: assessment } = useQuery({
    queryKey: ['assessment', job],
    queryFn: () =>
      PublicService.getAssessment({ assessmentId: job.assessment.id }),
    enabled: !!job,
  });

  const toggleAnswer = (questionId, answerId) => {
    setQuestionAnswers((prevAnswers) => {
      if (prevAnswers[questionId]?.includes?.(answerId)) {
        return {
          ...prevAnswers,
          [questionId]: prevAnswers[questionId]?.filter(
            (answer) => answer !== answerId
          ),
        };
      }
      return {
        ...prevAnswers,
        [questionId]: prevAnswers?.[questionId]
          ? [...prevAnswers[questionId], answerId]
          : [answerId],
      };
    });
  };

  const clearAll = () => {
    setQuestionAnswers({});
  };

  const submitAnswers = async () => {
    try {
      setIsFetching(true);

      const answerIds = [];
      Object.keys(questionAnswers).forEach((key) => {
        answerIds.push(...questionAnswers[key]);
      });

      const promises = answerIds.map((id) =>
        PublicService.answerAssessmentQuestion({
          assessmentId: assessment.id,
          answerId: id,
        })
      );

      await Promise.all(promises);

      await PublicService.submitAssessment({
        jobApplicationId: job.jobApplications[0].id,
      });

      setMode('success');
      releaseClosing();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const questionCount = assessment?.assessmentQuestions.length || 0;
  const questionsAnswered = Object.keys(questionAnswers).filter(
    (key) => questionAnswers[key].length
  ).length;

  useEffect(() => {
    if (questionsAnswered > 0) {
      preventClosing('quizAnswered');
    } else {
      releaseClosing();
    }
  }, [preventClosing, questionsAnswered, releaseClosing]);

  return (
    <div className={classes.Quiz}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <h3>
              {t(
                'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Quiz.headerText'
              )}
            </h3>
            <div className={classes.questionsLeft}>
              <div className={classes.title}>
                {t(
                  'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Quiz.questionsLeftTitle'
                )}
              </div>
              <div className={classes.count}>
                {questionsAnswered}/{questionCount}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.questions}>
          {assessment?.assessmentQuestions.map((question, index) => {
            return (
              <Question
                key={question.id}
                question={question}
                index={index + 1}
                toggleAnswer={toggleAnswer}
                questionAnswers={questionAnswers}
              />
            );
          })}
        </div>
      </div>
      <div className={classes.buttons}>
        <BlackButton
          onClick={clearAll}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
        >
          {t(
            'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.Screen.Assessment.Quiz.clearAllButton'
          )}
        </BlackButton>
        <Button
          disabled={questionsAnswered !== questionCount}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          style={{ fontSize: 24, fontWeight: 500 }}
          onClick={submitAnswers}
        >
          {t('common.submit')}
        </Button>
      </div>
    </div>
  );
}
