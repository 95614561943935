import React, { useState, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Assessment from '../JobApplicationStatuses/Screen/Assessment';
import StartDateOrScheduled from '../JobApplicationStatuses/Onboard/StartDateOrScheduled';
import ContactOrShareFeedback from '../ContactOrShareFeedback';
import Offered from '../JobApplicationStatuses/Offer/Offered';
import RejectionMessage from '../RejectionMessage';
import MessageWithButton from '../MessageWithButton';
import Invited from '../JobApplicationStatuses/New/Invited';
import Review from '../JobApplicationStatuses/New/Review';
import SetInterview from '../JobApplicationStatuses/Interview/SetInterview';

import useUiContext from '../../../../hooks/useUiContext';

export const useContentComponent = (
  currentJobApplication,
  refetch,
  hideJobsApplicationInfo
) => {
  const [shouldPreventClosing, setShouldPreventClosing] = useState(false);
  const [preventClosingReason, setPreventClosingReason] = useState(false);

  const { showModal } = useUiContext();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const preventClosing = useCallback((reason) => {
    setShouldPreventClosing(true);
    setPreventClosingReason(reason);
  }, []);

  const releaseClosing = useCallback(() => {
    setShouldPreventClosing(false);
    setPreventClosingReason('');
  }, []);

  const closeJobApplicationInfo = useCallback(() => {
    if (shouldPreventClosing) {
      if (preventClosingReason === 'quizAnswered') {
        showModal({
          title: t(
            'components.MyDashboard.JobApplicationInfo.assessmentExitTitle'
          ),
          text: t(
            'components.MyDashboard.JobApplicationInfo.assessmentExitMessage'
          ),
          onConfirm: () => {
            hideJobsApplicationInfo();
            releaseClosing();
          },
        });
      }
    } else {
      hideJobsApplicationInfo();
    }
  }, [
    hideJobsApplicationInfo,
    preventClosingReason,
    releaseClosing,
    shouldPreventClosing,
    showModal,
    t,
  ]);

  const setInterview = {
    component: SetInterview,
    props: {
      interview: currentJobApplication?.jobApplicationInterviews?.[0],
      jobApplicationId: currentJobApplication?.id,
      refetch,
      subStatus: currentJobApplication?.subStatus,
    },
  };

  const closedJob = {
    component: MessageWithButton,
    props: {
      buttonLabel: t('components.MyDashboard.JobApplicationInfo.browseJobs'),
      onButtonClick: () => navigate('/jobs'),
      message: (
        <p>
          {t('components.MyDashboard.JobApplicationInfo.closedJobMessage.0')}
          <br />
          <br />
          {t('components.MyDashboard.JobApplicationInfo.closedJobMessage.1')}
        </p>
      ),
    },
  };

  const contentComponents = {
    Invited: {
      Pending: {
        component: Invited,
        props: {
          job: currentJobApplication?.jobPost,
          jobApplicationId: currentJobApplication?.id,
          hasAssessment: currentJobApplication?.assignedAssessments?.length > 0,
          refetch,
        },
      },
      JobClosed: closedJob,
    },
    New: {
      JobClosed: closedJob,
      Pending: { component: Review },
      Invited: {
        component: Invited,
        props: {
          job: currentJobApplication?.jobPost,
          jobApplicationId: currentJobApplication?.id,
          hasAssessment: currentJobApplication?.jobPost?.assessment,
          refetch,
        },
      },
      Declined: {
        component: MessageWithButton,
        props: {
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForReviewingInvitationMessage.0'
              )}{' '}
              <br />
              <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForReviewingInvitationMessage.1'
              )}
            </p>
          ),
          buttonLabel: t(
            'components.MyDashboard.JobApplicationInfo.browseJobs'
          ),
          onButtonClick: () => navigate('/jobs'),
        },
      },
      Rejected: {
        component: RejectionMessage,
        props: {
          status: 'New',
        },
      },
      Accepted: {
        component: Review,
        props: {
          isInvited: true,
        },
      },
    },
    Screen: {
      JobClosed: closedJob,
      Pending: {
        component: Assessment,
        props: {
          currentJobApplication,
          hideJobsApplicationInfo: closeJobApplicationInfo,
          preventClosing,
          releaseClosing,
          refetch,
        },
      },
      Requested: {
        component: Assessment,
        props: {
          currentJobApplication,
          hideJobsApplicationInfo: closeJobApplicationInfo,
          preventClosing,
          releaseClosing,
          refetch,
        },
      },
      Retake: {
        component: Assessment,
        props: {
          currentJobApplication,
          hideJobsApplicationInfo: closeJobApplicationInfo,
          preventClosing,
          releaseClosing,
          refetch,
        },
      },
      Passed: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          jobApplicationId: currentJobApplication?.id,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage.0'
              )}
              <br />
              <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage.1'
              )}
            </p>
          ),
        },
      },
      Rejected: {
        component: RejectionMessage,
        props: {
          status: 'Screen',
        },
      },
      AutoRejected: {
        component: RejectionMessage,
        props: {
          status: 'Screen',
        },
      },
      Failed: {
        component: RejectionMessage,
        props: {
          status: 'Screen',
        },
      },
    },
    Interview: {
      JobClosed: closedJob,
      Pending: currentJobApplication?.assignedAssessments?.length
        ? {
            component: ContactOrShareFeedback,
            props: {
              hideJobsApplicationInfo,
              jobApplicationId: currentJobApplication?.id,
              message: (
                <p>
                  {t(
                    'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage2.0'
                  )}{' '}
                  <br />{' '}
                  {t(
                    'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage2.1'
                  )}
                  <br />
                  <br />{' '}
                  {t(
                    'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage2.2'
                  )}{' '}
                  <br />{' '}
                  {t(
                    'components.MyDashboard.JobApplicationInfo.thanksForCompletingAssessmentMessage2.3'
                  )}
                </p>
              ),
            },
          }
        : { component: Review },
      Scheduled: setInterview,
      Rescheduled: setInterview,
      Accepted: setInterview,
      ChangeRequested: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.recommendedInterviewScheduleMessage.0'
              )}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.recommendedInterviewScheduleMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
      Declined: {
        component: MessageWithButton,
        props: {
          buttonLabel: t(
            'components.MyDashboard.JobApplicationInfo.browseJobs'
          ),
          onButtonClick: () => navigate('/jobs'),
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.declinedInterviewMessage.0'
              )}
              <br />
              <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.declinedInterviewMessage.1'
              )}
            </p>
          ),
        },
      },
      Rejected: {
        component: RejectionMessage,
        props: {
          status: 'Interview',
        },
      },
      Passed: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          jobApplicationId: currentJobApplication?.id,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.interviewPassedMessage.0'
              )}{' '}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.interviewPassedMessage.1'
              )}
            </p>
          ),
        },
      },
      Canceled: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.interviewCanceledMessage.0'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
    },
    Offer: {
      JobClosed: closedJob,
      Pending: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.pendingOfferMessage.0'
              )}{' '}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.pendingOfferMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
      Offered: {
        component: Offered,
        props: {
          offer:
            currentJobApplication?.jobApplicationOffers[
              currentJobApplication?.jobApplicationOffers.length - 1
            ],
          jobId: currentJobApplication?.id,
          refetch,
        },
      },
      Countered: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.counterOfferMessage.0'
              )}{' '}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.counterOfferMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
          offer:
            currentJobApplication?.jobApplicationOffers[
              currentJobApplication?.jobApplicationOffers.length - 1
            ],
          jobId: currentJobApplication?.id,
        },
      },
      Accepted: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOfferMessage.0'
              )}{' '}
              <br /> <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOfferMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
          offer:
            currentJobApplication?.jobApplicationOffers[
              currentJobApplication?.jobApplicationOffers?.length - 1
            ],
          jobId: currentJobApplication?.id,
        },
      },
      Rejected: {
        component: RejectionMessage,
        props: {
          status: 'Offer',
        },
      },
      Declined: {
        component: MessageWithButton,
        props: {
          buttonLabel: t(
            'components.MyDashboard.JobApplicationInfo.browseJobs'
          ),
          onButtonClick: () => navigate('/jobs'),
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.offerRejectedMessage.0'
              )}
              <br />
              <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.offerRejectedMessage.1'
              )}
            </p>
          ),
        },
      },
    },
    Onboard: {
      JobClosed: closedJob,
      Pending: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOfferMessage.0'
              )}{' '}
              <br /> <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOfferMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
          offer:
            currentJobApplication?.jobApplicationOffers[
              currentJobApplication?.jobApplicationOffers?.length - 1
            ],
          jobId: currentJobApplication?.id,
        },
      },
      Rejected: {
        component: RejectionMessage,
        props: {
          status: 'Onboard',
        },
      },
      Scheduled: {
        component: StartDateOrScheduled,
        props: {
          schedule: currentJobApplication?.jobApplicationOnboardings[0],
          refetch,
          jobApplicationId: currentJobApplication?.id,
        },
      },
      ChangeRequested: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          jobApplicationId: currentJobApplication?.id,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.changeRequestedMessage.0'
              )}{' '}
              {currentJobApplication?.jobApplicationJoinings.length
                ? t(
                    'components.MyDashboard.JobApplicationInfo.changeRequestedMessage.1'
                  )
                : t(
                    'components.MyDashboard.JobApplicationInfo.changeRequestedMessage.2'
                  )}{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.changeRequestedMessage.3'
              )}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.changeRequestedMessage.4'
              )}
            </p>
          ),
        },
      },
      Accepted: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          withCalendarButton: true,
          schedule: currentJobApplication?.jobApplicationOnboardings[0],
          jobApplicationId: currentJobApplication?.id,
          scheduleType: 'Onboarding',
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOnboardMessage.0'
              )}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.thanksForAcceptingOnboardMessage.1'
              )}
            </p>
          ),
        },
      },
      Declined: {
        component: MessageWithButton,
        props: {
          buttonLabel: 'Browse Jobs',
          onButtonClick: () => navigate('/jobs'),
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.onboardRejectedMessage.0'
              )}
              <br />
              <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.onboardRejectedMessage.1'
              )}
            </p>
          ),
        },
      },
      StartDeclined: {
        component: MessageWithButton,
        props: {
          buttonLabel: 'Browse Jobs',
          onButtonClick: () => navigate('/jobs'),
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.onboardRejectedMessage.0'
              )}
              <br />
              <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.onboardRejectedMessage.1'
              )}
            </p>
          ),
        },
      },
      NoShow: {
        component: MessageWithButton,
        props: {
          noButton: true,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.abandonedApplicationMessage.0'
              )}
              <br />
              <br />
              {t(
                'components.MyDashboard.JobApplicationInfo.abandonedApplicationMessage.1'
              )}
            </p>
          ),
        },
      },
      StartDateSet: {
        component: StartDateOrScheduled,
        props: {
          startDateSet: true,
          schedule: {
            ...currentJobApplication?.jobApplicationJoinings[0],
            type: 'InPerson',
            interviewDate:
              currentJobApplication?.jobApplicationJoinings[0]?.joiningDate,
          },
          refetch,
          jobApplicationId: currentJobApplication?.id,
        },
      },
      StartConfirmed: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          withCalendarButton: true,
          schedule: currentJobApplication?.jobApplicationJoinings[0],
          scheduleType: 'Joining',
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.startConfirmedMessage.0'
              )}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.startConfirmedMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
      Joined: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t('components.MyDashboard.JobApplicationInfo.welcomeMessage.0')}
              <br /> <br />{' '}
              {t('components.MyDashboard.JobApplicationInfo.welcomeMessage.1')}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
      Onboarded: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t('components.MyDashboard.JobApplicationInfo.welcomeMessage.0')}
              <br /> <br />
              {t('components.MyDashboard.JobApplicationInfo.welcomeMessage.1')}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
      Canceled: {
        component: ContactOrShareFeedback,
        props: {
          hideJobsApplicationInfo,
          message: (
            <p>
              {t(
                'components.MyDashboard.JobApplicationInfo.startDateCanceledMessage.0'
              )}
              <br /> <br />{' '}
              {t(
                'components.MyDashboard.JobApplicationInfo.startDateCanceledMessage.1'
              )}
            </p>
          ),
          jobApplicationId: currentJobApplication?.id,
        },
      },
    },
  };

  const isJobPostUnpublished =
    currentJobApplication?.jobPost?.status === 'UnPublished';

  // If job post is unpublished, return the JobClosed component and props from contentComponents.Onboard.JobClosed
  // It doesn't matter what exact JobClosed component is returned, as they all are the same
  return {
    contentComponent: isJobPostUnpublished
      ? contentComponents.Onboard.JobClosed.component
      : contentComponents[currentJobApplication?.status]?.[
          currentJobApplication?.subStatus
        ]?.component,
    contentComponentProps: isJobPostUnpublished
      ? contentComponents.Onboard.JobClosed.props
      : contentComponents[currentJobApplication?.status]?.[
          currentJobApplication?.subStatus
        ]?.props || {},
    closeJobApplicationInfo,
  };
};
