import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { IMAGES_URL } from '../../../constants/main';
import UserService from '../../../services/UserService';
import classes from './styles.module.scss';

export default function UserBlock() {
  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { t } = useTranslation();

  return (
    <div className={classes.UserBlock}>
      {user?.userProfile.avatar && (
        <img
          src={`${IMAGES_URL}/avatar/${user.userProfile.avatar}?format=png&mode=crop&width=200&height=200&q=70`}
          alt="User"
          className={classes.userPhoto}
        />
      )}
      <h2 className={classes.userName}>
        {t('components.MyDashboard.JobApplicationInfo.greeting')}{' '}
        {user?.userProfile.firstName}!
      </h2>
    </div>
  );
}
