import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import InfoField from '../InfoField';

import classes from './styles.module.scss';

export default function ConfirmReschedule({
  date,
  startTime,
  rescheduledDate,
  rescheduledTime,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.ConfirmReschedule}>
      <div className={classes.scheduleDetails}>
        <h4 className={classes.heading}>
          {t(
            'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.ourSchedule'
          )}
        </h4>
        <div className={classes.row}>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.date'
              )}
              value={moment(date).format('DD/MM/YYYY')}
            />
          </div>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.time'
              )}
              value={startTime}
            />
          </div>
        </div>
      </div>
      <div className={classes.scheduleDetails}>
        <h4 className={classNames(classes.heading, classes.red)}>
          {t(
            'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.yourRecommendedSchedule'
          )}
        </h4>
        <div className={classes.row}>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.date'
              )}
              value={moment(rescheduledDate).format('DD/MM/YYYY')}
            />
          </div>
          <div className={classes.col}>
            <InfoField
              title={t(
                'components.MyDashboard.JobApplicationInfo.ConfirmReschedule.time'
              )}
              value={rescheduledTime}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
