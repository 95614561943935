/* eslint-disable no-unreachable */
import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Button from '../../../../../Button';
import BlackButton from '../../../BlackButton';

import { UiContext } from '../../../../../../context/UiContext';
import PublicService from '../../../../../../services/PublicService';
import UserService from '../../../../../../services/UserService';
import classes from './styles.module.scss';

export default function Invited({
  jobApplicationId,
  hasAssessment,
  refetch,
  job,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { setIsFetching } = useContext(UiContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const acceptInvite = async () => {
    try {
      setIsFetching(true);

      const hasNoVideoResume = !user?.userProfile?.userProfileVideoResume;
      const hasNoFileResume = !user?.userProfile?.userProfileFileResume;

      if (job?.applicationType === 'VideoOnly' && hasNoVideoResume) {
        navigate(
          `/jobs/${job.id}/apply/video?jobApplicationId=${jobApplicationId}`
        );
        return;
      }

      if (job?.applicationType === 'DocumentOnly' && hasNoFileResume) {
        navigate(
          `/jobs/${job.id}/apply/info?jobApplicationId=${jobApplicationId}`
        );
      }

      if (
        job?.applicationType === 'VideoAndDocument' &&
        (hasNoFileResume || hasNoVideoResume)
      ) {
        navigate(
          `/jobs/${job.id}/apply/video?jobApplicationId=${jobApplicationId}`
        );
        return;
      }

      if (
        job?.applicationType === 'VideoOrDocument' &&
        hasNoFileResume &&
        hasNoVideoResume
      ) {
        navigate(`/jobs/${job.id}/apply?jobApplicationId=${jobApplicationId}`);
        return;
      }

      if (hasAssessment) {
        await PublicService.acceptJobWithAssessmentInvite({ jobApplicationId });
      } else {
        await PublicService.acceptJobInvite({ jobApplicationId });
      }

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  console.log(hasAssessment);

  const declineInvite = async () => {
    try {
      setIsFetching(true);
      await PublicService.declineJobInvite({ jobApplicationId });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.Invited}>
      <p className={classes.info}>
        {t(
          'components.MyDashboard.JobApplicationInfo.JobApplicationStatuses.New.Invited.info'
        )}
      </p>
      <div className={classes.buttons}>
        <BlackButton
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={declineInvite}
        >
          {t('common.decline')}
        </BlackButton>
        <Button
          jobApplicationInfo
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          onClick={acceptInvite}
        >
          {t('common.accept')}
        </Button>
      </div>
    </div>
  );
}
