import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';

import classes from './styles.module.scss';

export default function NotificationButtons({
  buttonsData = {},
  markNotificationAsRead,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.NotificationButtons}>
      <Button
        height={30}
        style={{
          lineHeight: 0,
          minWidth: buttonsData.mainButtonWidth,
        }}
        onClick={(event) => {
          if (
            buttonsData.mainButtonTitle !==
            t('helpers.getNotificationButtonsData.review')
          ) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
          }
          markNotificationAsRead();
          buttonsData.onMainButtonClick();
        }}
      >
        {buttonsData.mainButtonTitle}
      </Button>
      {buttonsData.secondaryButtonTitle && (
        <Button
          likeDisabled
          height={30}
          style={{
            lineHeight: 0,
          }}
          onClick={(event) => {
            event.stopPropagation();
            buttonsData.onSecondaryButtonClick();
            markNotificationAsRead();
          }}
        >
          {buttonsData.secondaryButtonTitle}
        </Button>
      )}
    </div>
  );
}
