import React, { useState, useEffect, useRef, useContext } from 'react';

import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Loader from '../../components/Loader';
import Job from '../../components/Jobs/Job';
import Filters from '../../components/Jobs/Filters';
import JobsSearch from '../../components/JobsSearch';
import NavHeader from '../../components/NavHeader';

import { UiContext } from '../../context/UiContext';
import useIntersectionObserverPagination from '../../hooks/useIntersectionObserverPagination';
import classes from './styles.module.scss';
import AnonymousService from '../../services/AnonymousService';

function flattenPaginatedData(data) {
  return data?.pages?.map((page) => page.data.jobPosts).flat();
}

export default function JobsPage() {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState(t('pages.MyDashboardPage.latest'));
  const [currentFilter, setCurrentFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [city, setCity] = useState({});
  const [appliedCity, setAppliedCity] = useState({});

  const { isAuthenticated, isPreview } = useContext(UiContext);

  const { cityId, cityName } = useParams();

  const jobsPageRef = useRef();

  const navigate = useNavigate();

  const { data: cities } = useQuery({
    queryKey: ['cities'],
    queryFn: AnonymousService.getCities,
  });

  const cityOptions = cities?.map((ct) => ({
    label: ct.name,
    value: ct.id,
  }));

  const {
    data: jobsData,
    isFetching,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['jobs', cityId, currentFilter, sortOrder, searchTerm],
    queryFn: ({ pageParam = 1 }) =>
      AnonymousService.getJobs({
        LocationCityIds: cityId,
        JobTitle: currentFilter?.title,
        OrderBy: `createdAt:${
          sortOrder === t('pages.MyDashboardPage.latest') ? 'desc' : 'asc'
        }`,
        Term: searchTerm,
        PageNumber: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    keepPreviousData: true,
  });

  const jobs = flattenPaginatedData(jobsData);

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  useEffect(() => {
    setCity({ value: cityId, label: cityName });
  }, [cityId, cityName]);

  useEffect(() => {
    setCurrentFilter(null);
  }, [cityId]);

  useEffect(() => {
    if (appliedCity.value) {
      navigate(`/jobs/city/${appliedCity.value}/${appliedCity.label}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedCity]);

  return (
    <div
      className={classNames(classes.JobsPage, {
        [classes.preview]: isPreview,
      })}
      ref={jobsPageRef}
    >
      {isPreview && <div className={classes.overlay} />}
      {isFetching && <Loader />}
      <div className={classes.header}>
        <NavHeader top={6} />
        <JobsSearch
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSearchTerm={setSearchTerm}
          city={city}
          setCity={setCity}
          cityOptions={cityOptions}
          setAppliedCity={setAppliedCity}
        />
        <Filters
          filters={jobsData?.pages?.[0]?.data?.jobTitles.map((jobTitle) => ({
            title: jobTitle.jobTitle,
            count: jobTitle.count,
          }))}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
        />
        <div className={classes.cityName}>
          <h1>{cityName}</h1>
        </div>
      </div>
      <div className={classes.jobs}>
        {jobs?.map((job, index) => {
          return (
            <Job
              noFavorite={!isAuthenticated}
              stretch
              refetch={refetch}
              key={job.id}
              job={job}
              reference={index === jobs?.length - 1 ? lastListElementRef : null}
            />
          );
        })}
      </div>
    </div>
  );
}
