import axios from 'axios';

import getPaginatedData from '../helpers/getPaginatedData';

import { CMS_ANONYMOUS_API, JOBS_ANONYMOUS_API } from '../constants/main';

const AnonymousService = {
  async getJobCount() {
    const response = await axios.get(`${CMS_ANONYMOUS_API}/counts`);
    return response.data;
  },

  async getContentMedias() {
    const response = await axios.get(`${CMS_ANONYMOUS_API}/contentmedias`);
    return response.data;
  },

  async getCities() {
    const response = await axios.get(`${CMS_ANONYMOUS_API}/cities`);
    return response.data;
  },

  async getReferences() {
    const response = await axios.get(`${CMS_ANONYMOUS_API}/references`);
    return response.data;
  },

  async getJobs({ LocationCityIds, JobTitle, OrderBy, Term, PageNumber }) {
    const url = `${JOBS_ANONYMOUS_API}/jobposts`;
    return getPaginatedData({
      LocationCityIds,
      JobTitle,
      OrderBy,
      Term,
      PageNumber,
      url,
    });
  },

  async getJobById({ jobId }) {
    const response = await axios.get(`${JOBS_ANONYMOUS_API}/jobposts/${jobId}`);
    return response.data;
  },

  async createDropOff({ reason, email, jobApplicationId }) {
    const response = await axios.post(`${JOBS_ANONYMOUS_API}/dropoffs`, {
      reason,
      email,
      jobApplicationId,
    });
    return response.data;
  },
};

export default AnonymousService;
