import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';

import classes from './styles.module.scss';

export default function ReadyToGo({ questions, onClose }) {
  const { t } = useTranslation();

  return (
    <div className={classes.ReadyToGo}>
      <h1>{t('components.RecordVideo.MobileScreens.ReadyToGo.readyToGo')}</h1>
      <p className={classes.info}>
        {t('components.RecordVideo.MobileScreens.ReadyToGo.info.0')}
        <br /> {t('components.RecordVideo.MobileScreens.ReadyToGo.info.1')}{' '}
        <span className={classes.red}>
          {t('components.RecordVideo.MobileScreens.ReadyToGo.info.2')}{' '}
          {questions.length}{' '}
          {t('components.RecordVideo.MobileScreens.ReadyToGo.info.3')}
        </span>{' '}
        {t('components.RecordVideo.MobileScreens.ReadyToGo.info.4')}
      </p>
      <ul className={classes.videoSectionsList}>
        {questions.map((question, index) => {
          return (
            <li key={question.id}>
              <span>
                {index + 1}. {question.title}
              </span>
              <span className={classes.duration}>
                {question.duration}{' '}
                {t('components.RecordVideo.MobileScreens.ReadyToGo.sec')}
              </span>
            </li>
          );
        })}
      </ul>

      <p className={classes.help}>
        {t('components.RecordVideo.MobileScreens.ReadyToGo.helpText.0')}{' '}
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3623 11.2502C11.6562 10.4148 12.2362 9.71036 12.9997 9.26164C13.7633 8.81292 14.6609 8.6489 15.5338 8.79861C16.4066 8.94833 17.1983 9.40213 17.7687 10.0796C18.339 10.7571 18.6511 11.6146 18.6498 12.5002C18.6498 15.0002 14.8998 16.2502 14.8998 16.2502"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 21.25H15.0125"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.7314 16.4849V18.2811"
            stroke="black"
            strokeLinecap="round"
          />
        </svg>{' '}
        {t('components.RecordVideo.MobileScreens.ReadyToGo.helpText.1')}
      </p>
      <div className={classes.buttonContainer}>
        <Button style={{ fontSize: 22 }} onClick={onClose}>
          {t('components.RecordVideo.MobileScreens.ReadyToGo.letsGetStarted')}
        </Button>
      </div>
    </div>
  );
}
