import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DirectionsRenderer,
} from '@react-google-maps/api';
import classes from './styles.module.scss';
import closeIcon from '../../assets/close-icon.svg';
import customMarker from '../../assets/customMarker-icon.svg';
import currentPositionIcon from '../../assets/currentPosition-icon.svg';
import userCurrentPositionIcon from '../../assets/userCurrentPosition-icon.svg';
import logoIcon from '../../assets/images/logo.svg';

const libraries = ['places'];
function MapModal({ show, handleClose, locations, offer }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });
  const [currentPosition, setCurrentPosition] = useState({
    name: 'current',
    status: 'current',
    location: { lat: 43, lng: -80 },
  });
  const [directionResponse, setDirectionResponse] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState('');
  const [markers, setMarkers] = useState([]);

  // const center = { lat: 43, lng: -80 };

  useEffect(() => {
    if (!locations > 0 || !locations[0]?.latitude) return;
    const locdata = locations.map((location, i) => {
      return {
        name: location.name,
        city: location.city?.name,
        address: location.address,
        status: `status-${i}`,
        location: { lat: location.latitude, lng: location.longitude },
      };
    });
    setMarkers([
      ...locdata,
      {
        name: 'current',
        status: 'current',
        location: { lat: 43.5, lng: -80 },
      },
    ]);
  }, [locations]);

  useEffect(() => {
    if (offer) {
      setCurrentPosition({
        name: 'current',
        status: 'current',
        location: { lat: locations[0]?.latitude, lng: locations[0]?.longitude },
      });
    }
  }, [locations, offer]);

  const handleShowRoute = async (marker) => {
    const currentPosEl = markers.filter((el) => el.status === 'current');
    if (!currentPosEl || !marker.location) return;
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: currentPosEl[0].location,
      destination: marker.location,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionResponse(results);
  };

  const clearDirection = () => {
    setDirectionResponse(null);
  };

  const onDefineCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition({
        ...currentPosition,
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
      setMarkers([
        ...markers,
        {
          ...currentPosition,
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        },
      ]);
    });
  };

  if (!isLoaded) {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={classes.AlertModal}
        backdropClassName={classes.backdrop}
        contentClassName={classes.modalContent}
        dialogClassName={classes.dialog}
      >
        <div className={classes.content}>Loading...</div>
      </Modal>
    );
  }
  return (
    <Modal
      show={show}
      centered
      className={classes.AlertModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <button
          type="button"
          onClick={handleClose}
          className={classes.closeBtn}
        >
          <img
            width={30}
            height={30}
            className={classes.icon}
            src={closeIcon}
            alt="Icon"
          />
        </button>
        <GoogleMap
          zoom={10}
          center={
            currentPosition.location.lat
              ? currentPosition.location
              : markers[0]?.location
          }
          onClick={() => clearDirection()}
          mapContainerClassName={classes.map}
          mapContainerStyle={{ borderRadius: '20px' }}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControlOptions: {
              position: 6,
            },
          }}
        >
          {markers.map((marker) => {
            return (
              <Marker
                key={marker.name}
                position={marker.location}
                options={{
                  icon:
                    marker.status === 'current'
                      ? userCurrentPositionIcon
                      : customMarker,
                }}
                // onPositionChanged={(position) => console.log(position)}
                onClick={async () => {
                  try {
                    await handleShowRoute(marker);
                    setSelectedMarker(marker);
                  } catch (error) {
                    setSelectedMarker(marker);
                  }
                }}
              />
            );
          })}
          {directionResponse && (
            <DirectionsRenderer directions={directionResponse} />
          )}
          {selectedMarker && (
            <InfoWindow
              position={selectedMarker.location}
              options={{ pixelOffset: new window.google.maps.Size(0, -20) }}
            >
              <div className={classes.infoMockup}>
                <div className={classes['infoMockup__logo-wrap']}>
                  <img
                    width={80}
                    height={24}
                    className={classes.icon}
                    src={logoIcon}
                    alt="Icon"
                  />
                </div>
                <div className={classes['infoMockup__content-wrap']}>
                  {selectedMarker.status === 'current' ? (
                    <h1>Current position</h1>
                  ) : (
                    <>
                      <ul>
                        <li>
                          {selectedMarker.city && (
                            <p className={classes['infoMockup__content-city']}>
                              {selectedMarker.city}
                            </p>
                          )}
                        </li>
                        <li>
                          {selectedMarker.address && (
                            <p
                              className={classes['infoMockup__content-address']}
                            >
                              {selectedMarker.address}
                            </p>
                          )}
                        </li>
                        <li>
                          {selectedMarker.name && (
                            <p className={classes['infoMockup__content-name']}>
                              {selectedMarker.name}
                            </p>
                          )}
                        </li>
                      </ul>
                    </>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    // clearDirection();
                    setSelectedMarker('');
                  }}
                  className={classes.closeBtnInfo}
                >
                  <img
                    width={15}
                    height={15}
                    className={classes.icon}
                    src={closeIcon}
                    alt="Icon"
                  />
                </button>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
        <button
          type="button"
          onClick={onDefineCurrentPosition}
          className={classes.currentPositionBtn}
        >
          <img
            width={30}
            height={30}
            className={classes.icon}
            src={currentPositionIcon}
            alt="Icon"
          />
        </button>
      </div>
    </Modal>
  );
}

export default MapModal;
