import React from 'react';

import classes from './styles.module.scss';

export default function BlackButton({
  children,
  onClick,
  width,
  height,
  style,
}) {
  return (
    <button
      style={{ ...style, width, height }}
      className={classes.BlackButton}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
