/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { NavLink, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Notifications from '../../../components/Notifications';
import SignInModal from '../../../modals/SignInModal';
import MobileMenu from './MobileMenu';
import SearchBar from '../../../components/SearchBar';
import Languages from './Languages';

import { IMAGES_URL } from '../../../constants/main';
import isInIframe from '../../../helpers/isInIframe';
import { UiContext } from '../../../context/UiContext';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { clearAuthData } from '../../../helpers/authStorage';
// import defaultLogo from '../../../assets/images/logo.svg';
import classes from './styles.module.scss';

export default function Header({ setIsUserMenuVisible, logo }) {
  const [isSignInModalVisible, setIsSigninModalVisible] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBarWidth, setSearchBarWidth] = useState(322);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: i18next.language.toLocaleUpperCase(),
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const {
    isAuthenticated,
    setIsAuthenticated,
    isNotificationsPopupVisible,
    setIsNotificationsPopupVisible,
  } = useContext(UiContext);
  const isIframe = isInIframe();

  const mainNavRef = useRef();
  const searchButtonRef = useRef();
  const notificationsRef = useRef();
  const myNotificationsButtonRef = useRef();

  useOnClickOutside(
    notificationsRef,
    () => setIsNotificationsPopupVisible(false),
    myNotificationsButtonRef
  );

  const switchLanguage = (lang) => {
    i18next.changeLanguage(lang.name.toLowerCase());
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    if (searchParams.get('signin')) {
      setIsSigninModalVisible(true);
    }
  }, [searchParams]);

  const logout = () => {
    clearAuthData();
    setIsAuthenticated(false);
    queryClient.invalidateQueries();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (isTabletOrMobile) {
      return;
    }

    const calculateSearchBarWidth = () => {
      const { right: mainNavRight } =
        mainNavRef.current?.getBoundingClientRect();

      const { right: searchButtonRight } =
        searchButtonRef.current?.getBoundingClientRect();

      setSearchBarWidth(searchButtonRight - mainNavRight);
    };

    window.addEventListener('resize', calculateSearchBarWidth);

    setTimeout(() => {
      calculateSearchBarWidth();
    }, 0);

    return () => window.removeEventListener('resize', calculateSearchBarWidth);
  }, [isTabletOrMobile, language]);

  let logoUrl = null;

  if (logo) {
    logoUrl = logo.startsWith('data:image/')
      ? logo
      : `${IMAGES_URL}/brand/${logo.split('/')?.pop()}`;
  }

  const unathorizedLinks = (
    <>
      <li className={classes.listItem}>
        <NavLink to="/work">
          <span className={classes.navLink}>
            {t('components.Header.workAt')}{' '}
            <span>{t('components.Header.kfc')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink to="/life">
          <span className={classes.navLink}>
            {t('components.Header.lifeAt')}{' '}
            <span>{t('components.Header.kfc')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink to="/future">
          <span className={classes.navLink}>
            {t('components.Header.futureOf')}{' '}
            <span>{t('components.Header.kfc')}</span>
          </span>
        </NavLink>
      </li>
    </>
  );

  const authorizedLinks = (
    <>
      <li className={classes.listItem}>
        <NavLink
          to="/my-dashboard"
          className={({ isActive }) => (isActive ? classes.active : null)}
        >
          <span className={classes.navLink}>
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.dashboard')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          to="/favorites"
          className={({ isActive }) => (isActive ? classes.active : null)}
        >
          <span className={classes.navLink}>
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.favorites')}</span>
          </span>
        </NavLink>
      </li>
      <li className={classes.listItem}>
        <NavLink
          ref={myNotificationsButtonRef}
          to="/notifications"
          className={({ isActive }) => (isActive ? classes.active : null)}
          onClick={(event) => {
            if (!isTabletOrMobile) {
              event.preventDefault();
            }
          }}
        >
          <span
            className={classes.navLink}
            onClick={() => {
              if (!isTabletOrMobile) {
                setIsNotificationsPopupVisible((prevState) => !prevState);
              }
            }}
          >
            {t('components.Header.my')}{' '}
            <span>{t('components.Header.notifications')}</span>
          </span>
        </NavLink>
      </li>
    </>
  );

  return (
    <header
      className={classNames(classes.Header, {
        [classes.iframe]: isIframe,
      })}
    >
      <Link to="/" className={classes.logo}>
        <img src={logoUrl} alt="Logo" />
      </Link>
      <nav>
        <ul
          className={classNames(classes.mainNav, classes.list)}
          ref={mainNavRef}
        >
          {isAuthenticated && !isIframe ? authorizedLinks : unathorizedLinks}
        </ul>
      </nav>
      {isTabletOrMobile ? (
        <MobileMenu
          isSearchEnabled={isSearchEnabled}
          setIsSearchEnabled={setIsSearchEnabled}
          setSearchTerm={setSearchTerm}
          showSignInModal={() => setIsSigninModalVisible(true)}
          logout={logout}
          setIsUserMenuVisible={setIsUserMenuVisible}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={switchLanguage}
        />
      ) : (
        <ul className={classNames(classes.userMenu, classes.list)}>
          <li className={classes.listItem}>
            {isSearchEnabled && (
              <div className={classes.search}>
                <SearchBar
                  searchName="MainSearch"
                  setSearchTerm={setSearchTerm}
                  width={searchBarWidth}
                  onCloseSearchClick={() => setIsSearchEnabled(false)}
                />
              </div>
            )}
            <button
              ref={searchButtonRef}
              type="button"
              className={classes.searchButton}
              onClick={() => setIsSearchEnabled(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 22L20 20"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </li>
          <li className={classes.listItem}>
            <NavLink to="/help">
              <span className={classes.navLink}>
                {t('components.Header.help')}
              </span>
            </NavLink>
          </li>
          {isAuthenticated && !isIframe && (
            <li className={classes.listItem}>
              <NavLink to="/profile">
                <span className={classes.navLink}>
                  {t('components.Header.profile')}
                </span>
              </NavLink>
            </li>
          )}
          {!isIframe && (
            <li className={classes.listItem}>
              {isAuthenticated ? (
                <button
                  type="button"
                  className={classes.signInLink}
                  onClick={logout}
                >
                  <span className={classes.navLink}>
                    {t('components.Header.signOut')}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  className={classes.signInLink}
                  onClick={() => setIsSigninModalVisible(true)}
                >
                  <span className={classes.navLink}>
                    {t('components.Header.signIn')}
                  </span>
                </button>
              )}
            </li>
          )}
          <li className={classes.listItem}>
            <Languages
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={switchLanguage}
            />
          </li>
        </ul>
      )}
      <SignInModal
        show={isSignInModalVisible}
        handleClose={useCallback(() => setIsSigninModalVisible(false), [])}
      />
      {isNotificationsPopupVisible && (
        <Notifications reference={notificationsRef} />
      )}
    </header>
  );
}
