import React from 'react';

import classes from './styles.module.scss';

export default function WhiteButton({ children, onClick, width, height }) {
  return (
    <button
      className={classes.WhiteButton}
      type="button"
      onClick={onClick}
      style={{ width, height }}
    >
      {children}
    </button>
  );
}
