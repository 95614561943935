/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function VideoResume({ videos, onSelect, createdAt }) {
  const [currentVideo, setCurentVideo] = useState(0);

  const videoRef = useRef();

  const { t } = useTranslation();

  const toggleVideoPlayback = (event) => {
    event.stopPropagation();

    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const changeVideo = () => {
    setCurentVideo((prevVideo) => {
      if (prevVideo === videos.length - 1) {
        return 0;
      }
      return prevVideo + 1;
    });
  };

  const videoPlayers = videos
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    ?.map((video, index) => {
      return (
        <video
          autoPlay={index !== 0}
          src={video?.url}
          ref={index === currentVideo ? videoRef : null}
          onEnded={changeVideo}
        />
      );
    });

  return (
    <div className={classes.VideoResume}>
      {videos?.length ? (
        <span className={classes.createdAt}>
          {t('modals.PreviousJobApplicationModal.VideoResume.created')}{' '}
          {moment(createdAt).format('Do MMM YYYY')}
        </span>
      ) : null}
      <h2>
        {t('modals.PreviousJobApplicationModal.VideoResume.videoResumeBased')}
      </h2>
      <div className={classes.container}>
        {videos[0] ? (
          <div className={classes.videoContainer} onClick={onSelect}>
            {videoPlayers[currentVideo]}
            <button
              className={classes.playButton}
              type="button"
              onClick={toggleVideoPlayback}
            >
              {t('modals.PreviousJobApplicationModal.VideoResume.play')}
            </button>
          </div>
        ) : (
          <div className={classes.noResume}>
            <p>
              {t(
                'modals.PreviousJobApplicationModal.VideoResume.noApplicationPresent'
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
