/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import getBlobDuration from 'get-blob-duration';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import tooShortIcon from '../../assets/images/too-short.svg';
import awesomeIcon from '../../assets/images/awesome.svg';
import thumbUpIcon from '../../assets/images/thumb-up.svg';
import classes from './styles.module.scss';

export default function FinishRecordModal({
  show,
  handleClose,
  videosLeft,
  useVideo,
  file,
  time,
  retakeVideo,
  uploadMedia,
}) {
  const [isTooShort, setIsTooShort] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const getVideoDuration = async () => {
      try {
        const duration = await getBlobDuration(file);

        if (time * 0.33 > duration) {
          setIsTooShort(true);
        } else {
          setIsTooShort(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (file) {
      getVideoDuration();
    }
  }, [file, time]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.FinishRecordModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        {isTooShort ? (
          <img src={tooShortIcon} width={70} height={70} alt="Icon" />
        ) : (
          <img
            src={videosLeft ? thumbUpIcon : awesomeIcon}
            width={70}
            height={70}
            alt="Icon"
          />
        )}
        {isTooShort ? (
          <h1>{t('modals.FinishRecordModal.tooShort')}</h1>
        ) : (
          <h1>
            {videosLeft
              ? t('modals.FinishRecordModal.wellDone')
              : t('modals.FinishRecordModal.awesome')}
          </h1>
        )}
        <p
          className={classes.subtitle}
          style={{ visibility: isTooShort ? 'hidden' : 'visible' }}
        >
          {videosLeft
            ? `${videosLeft} ${t('modals.FinishRecordModal.moreToGo')}`
            : t('modals.FinishRecordModal.allSet')}
        </p>
        {isTooShort ? (
          <p className={classes.mainText}>
            {t('modals.FinishRecordModal.recordingTooShort')}
          </p>
        ) : (
          <p className={classes.mainText}>
            {videosLeft
              ? t('modals.FinishRecordModal.reviewRecordingOrMoveNext')
              : t('modals.FinishRecordModal.recomendToReview')}
          </p>
        )}
        <div className={classes.buttons}>
          <button
            className={classes.reviewButton}
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            {t('modals.FinishRecordModal.review')}
          </button>
          {isTooShort ? (
            <button
              className={classes.nextButton}
              type="button"
              onClick={() => {
                retakeVideo();
                handleClose();
              }}
            >
              {t('modals.FinishRecordModal.retake')}
            </button>
          ) : (
            <button
              className={classes.nextButton}
              type="button"
              onClick={() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useVideo();
                if (!videosLeft) {
                  uploadMedia();
                }
                handleClose();
              }}
            >
              {videosLeft
                ? t('modals.FinishRecordModal.recordNext')
                : t('modals.FinishRecordModal.upload')}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
