export function getTime(time) {
  const parts = time?.split(':');
  const hours = parseInt(parts?.[0], 10);
  const minutes = parseInt(parts?.[1], 10);

  const now = new Date();

  now.setUTCHours(hours);
  now.setUTCMinutes(minutes);

  const utcTime = new Date(now.toLocaleString('en-US'));

  const UTCHours = utcTime.getHours();
  const UTCMinutes = utcTime.getMinutes();

  return `${UTCHours.toString().padStart(
    2,
    '0'
  )}:${UTCMinutes.toString().padStart(2, '0')}`;
}

export function getTimezoneTime(time, timezone = 'Turkey') {
  if (!timezone) {
    return getTimezoneTime(time, 'Turkey');
  }

  if (!time) {
    return '00:00';
  }

  const parts = time?.split(':');
  const hours = parseInt(parts?.[0], 10);
  const minutes = parseInt(parts?.[1], 10);

  const now = new Date();

  now.setUTCHours(hours);
  now.setUTCMinutes(minutes);

  const utcTime = new Date(now.toLocaleString('en-US', { timeZone: timezone }));

  const UTCHours = utcTime.getHours();
  const UTCMinutes = utcTime.getMinutes();

  return `${UTCHours.toString().padStart(
    2,
    '0'
  )}:${UTCMinutes.toString().padStart(2, '0')}`;
}

export function getTimezoneDate(date, timezone, startTime, endTime) {
  if (!timezone) {
    return getTimezoneDate(date, 'Turkey', startTime, endTime);
  }

  const timezoneDate = new Date(
    new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      new Date(date).getHours(),
      new Date(date).getMinutes()
    )?.toLocaleString('en-US', {
      timeZone: `${timezone}`,
    })
  );

  const timezoneStartTime = getTimezoneTime(startTime, timezone);
  const timezoneEndTime = getTimezoneTime(endTime, timezone);

  return [timezoneDate, timezoneStartTime, timezoneEndTime];
}

export function setTime(time) {
  const currentDate = new Date();

  const [hours, minutes] = time.split(':');
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);

  const utc0Date = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    currentDate.getUTCHours(),
    currentDate.getUTCMinutes(),
    currentDate.getUTCSeconds()
  );

  const UTCHours = utc0Date.getHours();
  const UTCMinutes = utc0Date.getMinutes();

  return `${UTCHours.toString().padStart(
    2,
    '0'
  )}:${UTCMinutes.toString().padStart(2, '0')}`;
}

export function setDate(date, time = '00:00') {
  const newDate = new Date(date);
  const [hours, minutes] = time.split(':').map(Number);

  const localOffsetInMinutes = newDate.getTimezoneOffset();

  const timeDifferenceInMinutes = localOffsetInMinutes + 0;

  let utc0Hours = hours - Math.floor(timeDifferenceInMinutes / 60);
  const utc0Minutes = (minutes - timeDifferenceInMinutes) % 60;

  if (utc0Hours < 0) {
    utc0Hours += 24;
  }

  newDate.setHours(utc0Hours);
  newDate.setMinutes(utc0Minutes);

  return newDate;
}

export function getStaticDate(date) {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;
}
